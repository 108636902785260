<template>
  <SkeletonChart
    v-if="isLoading"
    class="chart-box"
    :style="[{'width': boxWidth}]"
  />
  <!-- scroll3 -->
  <div
    v-else-if="(outstandingData || outstandingData2 || outstandingData3) && !isLoading"
    class="chart-box scroll2"
    :style="[{'width': boxWidth}]"
  >
    <!-- TODO :::: bucle para varias cabeceras -->
    <div
      ref="charts"
      class="chart-box__top"
      :style="{'grid-template-columns': columns}"
    >
      <!-- <span
        v-if="showHelpTooltip"
        v-tooltip="{ content: helpTooltip, delay: 1, offset: 5, visible: true, class: 'tooltip-chart' }"
        class="icon icon-help"
      /> -->
      <!-- <ChartBoxHeader
        :entity="entity"
        :heavyData="heavyData"
        :heavyData2="heavyData2"
        :heavyData3="heavyData3"
        :chartLastdate="chartLastdate"
        :borderColor="borderColor"
        :styleGrid="columns"
      /> -->
      <ChartBoxHeader
        v-if="outstandingData"
        id="page-1"
        :entity="getEntity(0)"
        :heavyData="outstandingData"
        :heavyData2="outstandingData2"
        :chartLastdate="lastDateHeader[0]"
        :borderColor="borderColor[0]"
        :borderColor2="borderColor[1]"
        :subtitle="subtitle[0]"
        :subLabels="subLabels"
      />
      <ChartBoxHeader
        v-if="outstandingData2 && entity !== 'bloodPress'"
        id="page-2"
        :entity="getEntity(1)"
        :heavyData="outstandingData2"
        :chartLastdate="lastDateHeader[1]"
        :borderColor="borderColor[1]"
        :borderColorPrimary="borderColorPrimary[0]"
        :subtitle="subtitle[1]"
        :showTitle="entity2 !== null && showTitle2"
        :subLabels="subLabels2"
      />
      <ChartBoxHeader
        v-if="outstandingData3"
        id="page-3"
        :entity="getEntity(2)"
        :heavyData="outstandingData3"
        :chartLastdate="lastDateHeader[2]"
        :borderColor="borderColor[2]"
        :borderColorPrimary="borderColorPrimary[1]"
        :subtitle="subtitle[2]"
        :showTitle="entity3 !== null && showTitle3"
        :subLabels="subLabels3"
      />
    </div>
    <div
      ref="charts"
      class="chart-box__body"
      :style="{'grid-template-columns': columns}"
    >
      <slot />
    </div>
    <!-- TODO NAVEGACIÓN EN CHART DOBLE O TRIPLE -->
    <!-- <nav
      v-if="numberOfCharts > 1"
      style="display: flex; justify-content: center;"
    >
      <a href="#page-1"> • </a>
      <a href="#page-2"> • </a>
      <a href="#page-3"> • </a>
    </nav> -->
    <!-- PESTAÑAS INFERIORES: ANULADAS -->
    <!-- <div
      class="chart-box__footer"
      style="display: none"
    >
      <div class="chart-box__footer__labels">
        <span
          :class="{'selected' : currentLabel === 1}"
          class="chart-box__footer__labels__tab"
          @click="currentLabel = 1"
        >{{ $t('generalInfo.description') }}</span>
        <span
          :class="{'selected' : currentLabel === 2}"
          class="chart-box__footer__labels__tab"
          @click="currentLabel = 2"
        >{{ $t('generalInfo.recommendations') }}</span>
      </div>
      <div class="chart-box__footer__content">
        <span
          v-if="currentLabel === 1"
        >{{ description }}</span>
        <span
          v-if="currentLabel === 2"
        >{{ recommendations }}</span>
      </div>
    </div> -->
  </div>
</template>
<script>
import SkeletonChart from '@/components/digital-health/SkeletonChart.vue'
import ChartBoxHeader from '@/components/digital-health/ChartBoxHeader.vue'

export default {
  name: 'ChartBox',
  components: { SkeletonChart, ChartBoxHeader },
  props: {
    entity: {
      type: String,
      require: true,
      default: ''
    },
    entity2: {
      type: String,
      require: false,
      default: ''
    },
    entity3: {
      type: String,
      require: false,
      default: ''
    },
    subtitle: {
      type: Array,
      require: false,
      default: () => []
    },
    borderColor: {
      type: Array,
      require: true,
      default: () => []
    },
    borderColorPrimary: {
      type: Array,
      require: false,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      require: true
    },
    lastDateHeader: {
      type: Array,
      require: true,
      default: () => []
    },
    outstandingData: {
      type: Number,
      require: true,
      default: null
    },
    outstandingData2: {
      type: Number,
      require: false,
      default: null
    },
    outstandingData3: {
      type: Number,
      require: false,
      default: null
    },
    showTitle: {
      type: Boolean,
      require: false
    },
    showTitle2: {
      type: Boolean,
      require: false
    },
    showTitle3: {
      type: Boolean,
      require: false
    },
    subLabels: {
      type: Array,
      require: true,
      default: () => []
    },
    subLabels2: {
      type: Array,
      require: true,
      default: () => []
    },
    subLabels3: {
      type: Array,
      require: true,
      default: () => []
    }
  },
  data () {
    return {
      currentLabel: 1
    }
  },
  computed: {
    nCharts () {
      let count = 0
      if (this.outstandingData && this.entity !== '') {
        count += 1
      }
      if (this.outstandingData2 && this.entity2 !== '') {
        console.log('ENTRA 2')
        count += 1
      }
      if (this.outstandingData3 && this.entity3 !== '') {
        console.log('ENTRA 3')
        count += 1
      }
      return count
    },
    showHelpTooltip () {
      const target = `digitalHealth.charts.${this.entity}.help`
      return this.$t(target) !== '' && this.$t(target) !== null
    },
    helpTooltip () {
      const target = `digitalHealth.charts.${this.entity}.help`
      return this.$tc(target)
    },
    slotsNumber () {
      if (this.$refs.charts.children) {
        console.log(this.$refs.charts.children)
        return this.$refs.charts.children.length
      }
      return 0
    },
    columns () {
      console.log('entra en columns')
      return `repeat(${this.nCharts}, auto`
    },
    boxWidth () {
      // calculamos el ancho de las cajas en función de las gráficas que muestren: ancho + gap
      const gapWidth = {
        1: 0,
        2: 15,
        3: 30
      }
      /* 306 */
      return `${this.nCharts * 306 + gapWidth[this.nCharts]}px`
    }
  },
  mounted () {
    if (this.entity === 'vision') {
      console.log('this.outstandingData', this.outstandingData)
      if (this.outstandingData) {
        console.log(this.outstandingData)
      } else {
        console.log('NO HAY NADA')
      }
    }
  },
  methods: {
    getEntity: function (index) {
      if (this.entity2 && this.entity2 !== '' && index === 1) {
        return this.entity2
      } else if (this.entity3 && this.entity2 !== '' && index === 2) {
        return this.entity3
      } else if (this.entity && this.entity !== '' && index === 0) {
        return this.entity
      } else {
        return null
      }
    }
    /*
    counChilds () {
      // para determinar el número de columnas de la caja
      this.numberOfCharts = this.$refs.charts.childNodes.length
      // this.numberOfCharts = this.$refs.charts.children.length
      // this.$refs.charts.detailsChild.forEach(element => {
      //   console.log(element)
      // })
      console.log(this.entity)
    }
    */
  }
}
</script>

<style lang="scss">

@import 'src/assets/scss/settings/variables';
@import 'src/assets/scss/settings/colors';

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-spinner__icon.spinner .path {
  stroke: #655a84;
}

.chart-box {
  // border: 1px solid #B3B3B3;
  // width: 370px; // tamaño Figma
  // width: 295px;
  box-shadow: $depth-01;
  overflow-x: auto;
  margin-bottom: 5px;
  &__header {
    position: relative;
    // padding: 16px 32px 0 32px;
    // font-size: $font-size-sm;
    & h3 {
      font-size: $font-size-xxl;
    }
    & h4 {
      text-align: center;
    }
    & .icon.icon-help {
      position: absolute;
      font-size: 20px;
      right: -10px;
      opacity: 40%;
      color: var(--black-color);
      transition: all 0.3s ease-in-out;
      @media screen and (max-width: $media-mobile) {
        opacity: 40%;
      }
      &:hover {
          opacity: 1;
          // color: var(--black-color);
        }
    }
    // &:hover {
    //   & .icon.icon-help {
    //     opacity: .4;
    //     &:hover {
    //       opacity: 1;
    //       // color: var(--black-color);
    //     }
    //   }
    // }
    & .legend {
      display: grid;
      & > div {
        height: 30px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 0.9em;
      }
      & .icon-remove {
        font-weight: 800;
        font-size: 40px; // 50
      }
      & .legend--title {
        color: var(--black-60-color);
      }
    }
    & .heavy-data {
      height: 52px; // 75px
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      &--number {
        font-size: 38px; // 60
        font-weight: 400;
        color: var(--black-color);
        &.negative {
          color: var(--danger-color);
        }
        &.altered {
          color: var(--accent-light-color);
        }
      }
      &--measurement {
        margin-left: 10px;
        font-size: 28px; // 34
        color: var(--black-15-color);
        &--little {
          font-size: 16px; // 34
          color: var(--black-15-color);
        }
      }
      & .legend-other {
        & .icon-remove {
          font-weight: 800;
          font-size: 30px; // 50
        }
      }
    }
    & .date {
      color: var(--black-30-color);
      font-size: 15px;
    }
  }
  &__top {
    width: inherit;
    padding: 16px 32px;
    display: grid;
    gap: 40px;
    position: relative;
  }
  &__body {
    width: inherit;
    padding: 0 32px 16px 32px;
    display: grid;
    gap: 40px;
    position: relative;
  }
  &__footer {
    padding: 16px 32px;
    background-color: #fbfbfb;
    &__labels {
      display: flex;
      justify-content: center;
      gap: 15px;
      padding: 10px 0;
      &__tab {
        padding-bottom: 5px;
        font-family: "Helvetica";
        font-size: $font-size-sm;
        color: var(--black-45-color);
        cursor: pointer;
        &.selected {
          color: var(--primary-dark-color);
          border-bottom: 3px solid var(--primary-dark-color);
        }
      }
    }
    &__content {
      height: 100px;
      & span {
        color: var(--black-85-color);
        font-family: "Helvetica";
        font-size: $font-size-md;
        line-height: $line-height-xxl;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.tooltip-chart {
  color: var(--black-85-color);
  background-color: white;
  line-height: $line-height-xxl;
  box-shadow: $depth-03;
  padding: 15px;
}

</style>
