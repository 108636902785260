<template>
    <div class="card__list">
      <Toast :params="toastParams" v-if="isToastVisible"/>
      <div class="card__item" :class="{'card__item--previous': item.type === CARD_TYPE.APPOINMENTS && item.expired, 'yellow__background': item.medicalExamType === PHYSICAL_EXAMINATION_TYPE.VIP}" v-for="(item, index) in data" :key="index">
        <div class="card__icon-container" :class="getBGIcon(item.type)">
          <span class="card__icon icon" :class="getIcon(item.type)"></span>
        </div>
        <!-- CARD_TYPE.PHYSICAL_EXAMINATION -->
        <div class="card__content"  v-if="item.type === CARD_TYPE.PHYSICAL_EXAMINATION">
          <div class="card__header">
            <div class="card__title-container">
              <span class="card__title">{{ $t('menu.healthMonitoring.medicalExamination') }}</span>
              <span class="card__date">{{item.dates.initialDate | moment}}</span>
            </div>
            <span class="card__tag tag" :class="getStateClass(item)">{{ getStateCard(item) }}</span>
          </div>
          <div class="card__columns-container">
            <div class="card__first-column">
            <div class="card__group" v-if="item.medicalExamType === PHYSICAL_EXAMINATION_TYPE.VIP">
              <p class="card__label">{{ $t('generalInfo.medicalExamType') }}</p>
              <p class="card__data"><strong>{{ $t('generalInfo.VIPMedicalExamType') }}</strong></p>
            </div>
            <div class="card__group">
              <p class="card__label">{{ $t('generalInfo.workplace') }}</p>
              <p class="card__data card__data--xl">{{item.jobName}}</p>
            </div>
            <div class="card__group">
              <p class="card__label">{{ $t('generalInfo.motive') }}</p>
              <p class="card__data">{{item.motive}}</p>
            </div>
            <div class="card__group" v-if="item.protocols.length > 0">
              <p class="card__label">{{ $t('generalInfo.protocols') }}</p>
              <div class="card__chips-container">
                <!--<span class="card__chip" v-for="(protocol, index) in item.protocols" :key="index">{{protocol.name}}</span>-->
                <span :class="{'darker__yellow__background': item.medicalExamType === PHYSICAL_EXAMINATION_TYPE.VIP}" class="card__chip" v-for="(protocol, index) in getFilteredProtocols(item.protocols)" :key="index">{{protocol.name}}</span>
              </div>
            </div>
            </div>
            <div class="card__second-column" :class="{'darker__yellow__background': item.medicalExamType === PHYSICAL_EXAMINATION_TYPE.VIP}">
              <div class="card__group margin-top-0">
                <p class="card__label">{{ $t('generalInfo.nextRecognition') }}</p>
                <p class="card__data">{{item.dates.nextRmDate | moment}}</p>
              </div>
              <div class="card__group">
                <p class="card__label">{{ $t('generalInfo.periodicity') }}</p>
                <p class="card__data">{{item.periodicity}}</p>
              </div>
            </div>
          </div>
          <div class="card__links-container" v-if="item.uuidRm">
            <div class="card__link" @click="openPreview(item.uuidRm, item.type)">
              <span class="card__link__icon icon icon-show"></span>
              <span class="card__link__label">{{ $t('global.preview') }}</span>
            </div>
            <div class="card__link" @click="openDownload(item.uuidRm, item.type, index)">
              <span :class="[isLoading[index] ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-download']"></span>
              <span class="card__link__label">{{ $t('global.download') }}</span>
            </div>
            <div class="card__link" @click="openShareModal(item.uuidRm, item.type)">
              <span class="card__link__icon icon icon-mail"></span>
              <span class="card__link__label">{{ $t('global.send') }}</span>
            </div>
          </div>
        </div>
        <!-- CARD_TYPE.FITNESS_SUMMARY -->
        <div class="card__content"  v-if="item.type === CARD_TYPE.FITNESS_SUMMARY">
          <div class="card__header">
            <div class="card__title-container">
              <span class="card__title">{{ $t('menu.healthMonitoring.proficiencyReport') }}</span>
              <span class="card__date">{{item.dates.initialDate | moment}}</span>
            </div>
            <span class="card__tag tag" :class="getStateClass(item)">{{ getStateCard(item) }}</span>
          </div>
          <div class="card__columns-container">
            <div class="card__first-column">
              <div class="card__group">
              <p class="card__label">{{ $t('generalInfo.workplace') }}</p>
              <p class="card__data card__data--xl">{{item.jobName}}</p>
            </div>
            <div class="card__group" v-if="item.protocols.length > 0">
              <p class="card__label">{{ $t('generalInfo.protocols') }}</p>
              <div class="card__chips-container">
                <span class="card__chip" v-for="(protocol, index) in getFilteredProtocols(item.protocols)" :key="index">{{protocol.name}}</span>
              </div>
            </div>
            <div class="card__group" v-if="getFilteredAptitude(item.aptitudes).length > 0">
              <p class="card__label">{{ $t('generalInfo.aptitude') }}</p>
              <p class="card__data">{{ getFilteredAptitude(item.aptitudes)[0].aptitude }}</p>
            </div>
           <div class="card__group" v-if="item.aptitudeNotes">
              <p class="card__label">{{ $t('generalInfo.observations') }}</p>
              <p class="card__data">{{item.aptitudeNotes}}</p>
            </div>
            </div>
          </div>
          <div class="card__links-container" v-if="item.uuidRm">
            <div class="card__link" @click="openPreview(item.uuidRm, item.type)">
              <span class="card__link__icon icon icon-show"></span>
              <span class="card__link__label">{{ $t('global.preview') }}</span>
            </div>
            <div class="card__link" @click="openDownload(item.uuidRm, item.type, index)">
              <span :class="[isLoading[index] ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-download']"></span>
              <span class="card__link__label">{{ $t('global.download') }}</span>
            </div>
            <div class="card__link" @click="openShareModal(item.uuidRm, item.type)">
              <span class="card__link__icon icon icon-mail"></span>
              <span class="card__link__label">{{ $t('global.send') }}</span>
            </div>
          </div>
        </div>
        <!-- CARD_TYPE.ANALYTICS -->
        <div class="card__content"  v-if="item.type === CARD_TYPE.ANALYTICS">
          <div class="card__header">
            <div class="card__title-container">
              <span class="card__title">{{ $t('menu.healthMonitoring.analytics') }}</span>
              <span class="card__date">{{item.date | moment}}</span>
            </div>
          </div>
          <div :class="['card__columns-container', !item.uuid ? 'no-boder-bottom' : '']">
            <div class="card__first-column">
              <div class="card__group" v-if="item.notes">
                <p class="card__label"><b>{{ $t('generalInfo.observations') }}</b></p>
                <p class="card__data">{{item.notes}}</p>
              </div>
              <span class="card__separator"></span>
              <div class="card__group card__group--space-between">
                <div class="flex direction-column padding-right-10">
                  <p class="card__data card__data--xl primary-dark-color">{{ item.clientName }}</p>
                  <p class="card__label">{{ item.centerName }}</p>
                </div>
                <!-- <img :src="getCompanyLogo(item.company.logo)" class="card__logo" v-if="item.company.logo"> -->
              </div>
            </div>
          </div>
          <div class="card__links-container " v-if="item.uuid">
            <div class="card__link" @click="openPreview(item.uuid, item.type)">
              <span class="card__link__icon icon icon-show"></span>
              <span class="card__link__label">{{ $t('global.preview') }}</span>
            </div>
            <div class="card__link" @click="openDownload(item.uuid, item.type, index)">
              <span :class="[isLoading[index] ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-download']"></span>
              <span class="card__link__label">{{ $t('global.download') }}</span>
            </div>
            <div class="card__link" @click="openShareModal(item.uuid, item.type)">
              <span class="card__link__icon icon icon-mail"></span>
              <span class="card__link__label">{{ $t('global.send') }}</span>
            </div>
          </div>
        </div>
        <!-- CARD_TYPE.TRAINING -->
        <div class="card__content"  v-if="item.type === CARD_TYPE.TRAINING">
          <div class="card__header">
            <div class="card__title-container">
              <span class="card__title">{{item.courseName}}</span>
              <span class="card__date">{{item.id.date | moment}}</span>
            </div>
            <!--<span class="card__tag tag" :class="getStateClass(item)">{{ getStateCard(item.state) }}</span>-->
          </div>
          <div class="card__columns-container">
            <div class="card__first-column">
              <!-- <div class="card__group">
                <p class="card__label">{{ $t('generalInfo.duration') }}</p>
                <div class="flex align-items-center">
                  <p class="card__data card__data--xl margin-right-10">{{ $tc('openClassroomCalendar.hours', 4) }}</p>
                  <span class="tag">{{item.label}}</span>
                </div>
              </div> -->
              <div class="card__group" v-if="item.personalTrainer">
                <p class="card__label">{{ $t('generalInfo.personalTrainer') }}</p>
                <p class="card__data">{{item.personalTrainer}}</p>
              </div>
              <div class="card__group">
                <p class="card__label">{{ $t('generalInfo.center') }}</p>
                <p class="card__data">{{item.workplaceName}}</p>
              </div>
            <span class="card__separator"></span>
            <div class="card__group card__group--space-between">
              <div class="flex direction-column padding-right-10">
                <p class="card__data card__data--xl primary-dark-color">{{ item.clientName }}</p>
                <p class="card__label">{{ item.workplaceName }}</p>
              </div>
              <!-- <img :src="getCompanyLogo(item.company.logo)" class="card__logo" v-if="item.company.logo"> -->
            </div>
            </div>
          </div>
          <div class="card__links-container" v-if="item.uuid">
            <div class="card__link" @click="openPreview(item.uuid, item.type)">
              <span class="card__link__icon icon icon-show"></span>
              <span class="card__link__label">{{ $t('global.preview') }}</span>
            </div>
            <div class="card__link" @click="openDownload(item.uuid, item.type, index)">
              <span :class="[isLoading[index] ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-download']"></span>
              <span class="card__link__label">{{ $t('global.download') }}</span>
            </div>
            <div class="card__link" @click="openShareModal(item.uuid, item.type)">
              <span class="card__link__icon icon icon-mail"></span>
              <span class="card__link__label">{{ $t('global.send') }}</span>
            </div>
          </div>
        </div>
        <!-- CARD_TYPE.WORKPLACE -->
        <div class="card__content" v-if="item.type === CARD_TYPE.WORKPLACE">
          <div class="card__header">
            <div class="card__title-container">
              <span class="card__title">{{item.laborRelation.jobName}}</span>
              <span class="card__date">{{ getActiveFrom(item.laborRelation.initialDate) }}</span>
            </div>
            <!-- <span class="card__tag tag" :class="getStateClass(item)">{{ getStateCard(item.state) }}</span> -->
          </div>
          <div class="card__columns-container card__columns-container--no-column">
            <div class="card__first-column padding-right-0">
              <!--<div class="card__group">
                <p class="card__label">{{ $t('generalInfo.workplace') }}</p>
                <p class="card__data card__data--xl">{{item.laborRelation.jobName}}</p>
              </div>-->
            <div class="card__group" v-for="(additionalJob, index) in item.additionalJobs" :key="index">
              <p class="card__label">{{ $t('generalInfo.additionalPosition') }}</p>
              <p class="card__data card__data--xl">{{additionalJob.additionalJobName}}</p>
              <p class="card__data">{{ getActiveFrom(additionalJob.initialDate) }}</p>
            </div>
            <span class="card__separator"></span>
            <div class="card__group card__group--space-between">
              <div class="flex direction-column padding-right-10">
                <p class="card__data card__data--xl primary-dark-color">{{ item.laborRelation.clientName }}</p>
                <p class="card__label">{{ item.laborRelation.workplaceName }}</p>
              </div>
              <!--<img :src="getCompanyLogo(item.company.logo)" class="card__logo" v-if="item.company.logo">-->
            </div>
            </div>
          </div>
        </div>
        <!-- CARD_TYPE.EMPLOYMENT_HISTORY -->
        <div class="card__content" v-if="item.type === CARD_TYPE.EMPLOYMENT_HISTORY">
          <div class="card__header">
            <div class="card__title-container">
              <span class="card__title">{{item.laborRelation.jobName}}</span>
              <span class="card__date">{{item.laborRelation.workplaceName}}</span>
            </div>
          </div>
          <div class="card__columns-container card__columns-container--no-column">
            <div class="card__first-column">
              <div class="flex align-items-center" v-if="item.laborRelation.schedule || item.laborRelation.schedule != ''">
                <span class="card__content-icon icon icon-clock"></span>
                <span class="black-60-color line-height-1">{{ $t('employmentHistory.schedule.morning')}} - {{ $t('employmentHistory.schedule.afternoon')}}</span>
              </div>
              <div class="card__group">
                <p class="card__label">Del {{item.laborRelation.initialDate | moment}} al {{item.laborRelation.endDate | moment}}</p>
                <p class="card__data">{{item.dates}}</p>
              </div>
              <span class="card__separator"></span>
              <div class="card__group card__group--space-between">
                <div class="flex direction-column padding-right-10">
                  <p class="card__data card__data--xl primary-dark-color">{{ item.laborRelation.clientName }}</p>
                  <p class="card__label">{{ item.laborRelation.workplaceName }}</p>
                </div>
                <!--<img :src="getCompanyLogo(item.company.logo)" class="card__logo" v-if="item.company.logo">-->
              </div>
            </div>
          </div>
        </div>
        <!-- CARD_TYPE.RISKS_EVALUATION -->
        <div class="card__content" v-if="item.type === CARD_TYPE.RISKS_EVALUATION">
          <div class="card__header">
            <div class="card__title-container">
              <span class="card__title">{{ $t('risksEvaluation.types.' + item.title)}}</span>
              <span class="card__date" v-if="item.date">{{item.date | moment}}</span>
              <span class="card__date" v-else-if="item.date2">{{item.date2 | moment}}</span>
            </div>
          </div>
          <div class="card__columns-container">
            <div class="card__first-column">
              <div class="card__group" v-if="item.titleType !== 1">
                <p class="card__label">{{ $t('risksEvaluation.denomination') }}</p>
                <p class="card__data card__data--xl">{{item.denomination}}</p>
              </div>
              <div class="card__group">
                <p class="card__label">{{ $t('generalInfo.workplace') }}</p>
                <p class="card__data card__data--xl">{{item.jobName}}</p>
              </div>
              <span class="card__separator"></span>
              <div class="card__group card__group--space-between">
                <div class="flex direction-column padding-right-10">
                  <p class="card__data card__data--xl primary-dark-color">{{ item.clientName }}</p>
                  <p class="card__label">{{ item.workplaceName }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card__links-container"><!-- v-if="item.uuid" -->
            <div class="card__link" @click="$emit('onClickPreview', item)">
              <span class="card__link__icon icon icon-show"></span>
              <span class="card__link__label">{{ $t('global.preview') }}</span>
            </div>
            <div class="card__link" @click="$emit('onClickDownload', item)">
              <span :class="[item.isLoading ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-download']"></span>
              <span class="card__link__label">{{ $t('global.download') }}</span>
            </div>
            <div class="card__link" @click="$emit('onClickShareLink', item)">
              <span class="card__link__icon icon icon-mail"></span>
              <span class="card__link__label">{{ $t('global.send') }}</span>
            </div>
          </div>
        </div>
        <!-- CARD_TYPE.ICPT -->
        <div class="card__content" v-if="item.type === CARD_TYPE.ICPT">
          <div class="card__header">
            <div class="card__title-container">
              <span class="card__title">{{ $t('menu.technicalPrevention.informationConsultationCard') }}</span>
              <span class="card__date">{{item.initialDate | moment}}</span>
            </div>
          </div>
          <div class="card__columns-container">
            <div class="card__first-column">
              <div class="card__group">
                <p class="card__label">{{ $t('generalInfo.workplace') }}</p>
                <p class="card__data card__data--xl">{{item.jobName}}</p>
              </div>
              <span class="card__separator"></span>
              <div class="card__group">
                <div class="flex direction-column padding-right-10">
                  <p class="card__data card__data--xl primary-dark-color">{{ item.clientName }}</p>
                  <p class="card__label">{{ item.workplaceName }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="card__columns-container">
            <div class="card__first-column">
              <div class="card__group">
                <p class="card__label">{{ $t('generalInfo.workplace') }}</p>
                <p class="card__data card__data--xl">asdf asdfasdf asdfasdf asdfasdf asdfasdf asdf </p>
              </div>
              <div class="card__group">
                <p class="card__label">{{ $t('generalInfo.motive') }}</p>
                <p class="card__data">asdf asdf</p>
              </div>
            </div>
          </div> -->
          <div class="card__links-container"><!-- v-if="item.uuid" -->
            <div class="card__link" @click="$emit('onClickPreview', item)">
              <span class="card__link__icon icon icon-show"></span>
              <span class="card__link__label">{{ $t('global.preview') }}</span>
            </div>
            <div class="card__link" @click="$emit('onClickDownload', item)">
              <span :class="[item.isLoading ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-download']"></span>
              <span class="card__link__label">{{ $t('global.download') }}</span>
            </div>
            <div class="card__link" @click="$emit('onClickShareLink', item)">
              <span class="card__link__icon icon icon-mail"></span>
              <span class="card__link__label">{{ $t('global.send') }}</span>
            </div>
          </div>
        </div>
        <!-- CARD_TYPE.PERFORMED_TESTS -->
        <div class="card__content"  v-if="item.type === CARD_TYPE.PERFORMED_TESTS">
          <div class="card__header">
            <div class="card__title-container">
              <span class="card__title">{{item.activityMaster}}</span>
              <span class="card__date">{{item.date | moment}}</span>
            </div>
            <!--<span class="card__tag tag" :class="getStateClass(item)">{{ getStateCard(item.state) }}</span>-->
          </div>
          <div class="card__columns-container">
            <div class="card__first-column">
              <div class="card__group" v-if="item.notes">
                <p class="card__label"><b>{{ $t('generalInfo.observations') }}</b></p>
                <p class="card__data">{{item.notes}}</p>
              </div>
              <div class="card__group" v-if="item.recommendations">
                <p class="card__label"><b>{{ $t('generalInfo.recommendations') }}</b></p>
                <p class="card__data">{{item.recommendations}}</p>
              </div>
              <span class="card__separator"></span>
              <div class="card__group card__group--space-between">
                <div class="flex direction-column padding-right-10">
                  <p class="card__data card__data--xl primary-dark-color">{{ item.clientName }}</p>
                  <p class="card__label">{{ item.centerName }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card__links-container" v-if="item.uuid">
            <div class="card__link" @click="openPreview(item.uuid, item.type)">
              <span class="card__link__icon icon icon-show"></span>
              <span class="card__link__label">{{ $t('global.preview') }}</span>
            </div>
            <div class="card__link" @click="openDownload(item.uuid, item.type, index)">
              <span :class="[isLoading[index] ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-download']"></span>
              <span class="card__link__label">{{ $t('global.download') }}</span>
            </div>
            <div class="card__link" @click="openShareModal(item.uuid, item.type)">
              <span class="card__link__icon icon icon-mail"></span>
              <span class="card__link__label">{{ $t('global.send') }}</span>
            </div>
          </div>
        </div>
        <!-- CARD_TYPE.OTHER_DOCUMENTATION -->
        <div class="card__content"  v-if="item.type === CARD_TYPE.OTHER_DOCUMENTATION">
          <div class="card__header">
            <div class="card__title-container">
              <span class="card__title">{{item.name}}</span>
              <span class="card__date">{{item.initialDate | moment}}</span>
            </div>
            <span class="card__tag tag" :class="getStateClass(item)">{{ getStateCard(item.state) }}</span>
          </div>
          <div class="card__columns-container">
            <div class="card__first-column">
              <div class="card__group">
                <p class="card__data">{{item.description}}</p>
              </div>
              <span class="card__separator"></span>
              <div class="card__group card__group--space-between">
                <div class="flex direction-column padding-right-10">
                  <p class="card__data card__data--xl primary-dark-color">{{ item.clientName }}</p>
                  <p class="card__label">{{ item.clientName }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card__links-container" v-if="item.uuid">
            <div class="card__link" @click="openPreview(item.uuid, item.type)">
              <span class="card__link__icon icon icon-show"></span>
              <span class="card__link__label">{{ $t('global.preview') }}</span>
            </div>
            <div class="card__link" @click="openDownload(item.uuid, item.type, index)">
              <span :class="[isLoading[index] ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-download']"></span>
              <span class="card__link__label">{{ $t('global.download') }}</span>
            </div>
            <div class="card__link" @click="openShareModal(item.uuid, item.type)">
              <span class="card__link__icon icon icon-mail"></span>
              <span class="card__link__label">{{ $t('global.send') }}</span>
            </div>
          </div>
        </div>
        <!--CARD_TYPE.APPOINMENTS-->
        <div class="card__content" v-if="item.type === CARD_TYPE.APPOINMENTS">
            <div class="card__header">
                <div class="card__title-container">
                    <!--<span class="card__title">20 diciembre de 2021, jueves</span>-->
                    <span class="card__title">{{item.date | moment2}}</span>
                </div>
                <div class="card__icon-group">
                    <span class="card__icon-group__icon card__icon-group__icon--l icon icon-clock"></span>
                    <span class="card__icon-group__label card__icon-group__label--xxl">{{item.date | hour}}</span>
                </div>
            </div>
            <span class="card__tag tag tag--danger" v-if="!item.informedConsent && !item.expired">{{ $t('consentForm.title_1') + ' ' + $t('consentForm.expired') }}</span>
            <!-- <span class="card__tag tag tag--success" v-if="item.informedConsent && !item.expired">{{ $t('consentForm.title_1') + ' ' + $t('consentForm.success') }}</span> -->
            <div :class="['card__columns-container', item.expired ? 'no-boder-bottom' : '']">
                <div class="card__first-column">
                    <div class="card__group">
                        <p class="card__label">{{ $t('myAppoinments.appoinmentMotive') }}</p>
                        <p class="card__data black-color">{{ item.typeTitle }} / {{ item.reason }}</p>
                    </div>
                    <div class="card__group">
                        <p class="card__label">{{ $t('myAppoinments.medicalCenter') }}</p>
                        <p class="card__data black-color">{{ item.medicalCenterName }}</p>
                    </div>
                    <div class="card__icon-group__container margin-top-20" v-if="item.medicalCenterInfo">
                        <div class="card__icon-group card__icon-group--mobile-button"  v-if="item.medicalCenterInfo.phone">
                            <span class="card__icon-group__icon icon icon-phone-line"></span>
                            <span class="card__icon-group__label">{{ getCallLabel(item.medicalCenterInfo.phone) }}</span>
                        </div>
                        <div class="card__icon-group card__icon-group--mobile-button" v-if="getLocationLink(item.medicalCenterInfo) && !item.expired">
                            <span class="card__icon-group__icon icon icon-location"></span>
                            <a :href="getLocationLink(item.medicalCenterInfo)" target="_blank" class="card__icon-group__label">{{ $t('myAppoinments.howToGetThere') }}</a>
                        </div>
                        <div class="card__icon-group card__icon-group--mobile-button card__icon-group--mobile-only-icon" v-if="item.medicalCenterInfo.web">
                            <span class="card__icon-group__icon icon icon-browse"></span>
                            <a class="card__icon-group__label">{{ $t('myAppoinments.website') }}</a>
                        </div>
                        <!-- <span class="card__link__icon icon icon-location margin-left-30"></span>
                        <span class="card__label black-color">{{ $t('myAppoinments.howToGetThere') }}</span>
                        <span class="card__link__icon icon icon-external-link margin-left-30"></span>
                        <a href="" class="card__label black-color">{{ $t('myAppoinments.website') }}</a> -->
                    </div>
                </div>
            </div>
            <div class="card__links-container" v-if="item.expired && item.informedConsent">
                <div class="card__link" @click="openDownloadConsentForm(item, index)">
                  <span :class="[isLoading[index] ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-download']"></span>
                  <span class="card__link__label">{{ $t('consentForm.download') }}</span>
                </div>
            </div>
            <div class="card__links-container" v-if="!item.expired">
              <div class="card__link" v-if="item.informedConsent" @click="openDownloadConsentForm(item, index)">
                <span :class="[isLoading[index] ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-download']"></span>
                <span class="card__link__label">{{ $t('consentForm.download') }}</span>
              </div>
              <div class="card__link" v-if="!item.medicalExaminationId" @click="openConsentForm(item)">
                  <span class="card__link__icon icon icon-edit"></span>
                  <span v-if="item.informedConsent" class="card__link__label">{{ $t('consentForm.edit') }}</span>
                  <span v-if="!item.informedConsent" class="card__link__label">{{ $t('consentForm.fill') }}</span>
              </div>
              <div class="card__link" @click="openCancelAppointment(item)">
                  <span class="card__link__icon icon icon-delete"></span>
                  <span class="card__link__label">{{ $t('myAppoinments.cancelAppoinment') }}</span>
              </div>
            </div>
        </div>
        <!-- CARD_TYPE.UPLOADED_DOCUMENTATION -->
        <div class="card__content"  v-if="item.type === CARD_TYPE.UPLOADED_DOCUMENTATION">
          <div class="card__header">
            <div class="card__title-container">
              <span class="card__title">{{item.userFileName ? item.userFileName : item.fileName}}</span>
              <span class="card__date">{{item.createdAt | moment}}</span>
            </div>
          </div>
          <div class="card__columns-container">
            <div class="card__first-column">
              <div class="card__group">
                <span class="card__label">{{ $t('documentsUpload.documentType') }}</span>
                <span class="card__data card__data--md">{{ item.fileType }}</span>
              </div>
              <div class="card__group">
                <span class="card__label">{{ $t('generalInfo.description') }}</span>
                <p class="card__data card__data--md">{{item.description}}</p>
              </div>
            </div>
          </div>
          <div class="card__links-container" v-if="true"><!-- v-if="item.uuid" -->
            <div class="card__link" @click="$emit('onClickPreview', item)" v-if="item.fileExtension === 'pdf'">
              <span class="card__link__icon icon icon-show"></span>
              <span class="card__link__label">{{ $t('global.preview') }}</span>
            </div>
            <div class="card__link" @click="$emit('onClickDownload', item)">
              <span :class="[item.isLoading ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-download']"></span>
              <span class="card__link__label">{{ $t('global.download') }}</span>
            </div>
            <div class="card__link" @click="$emit('onClickShareLink', item)">
              <span class="card__link__icon icon icon-mail"></span>
              <span class="card__link__label">{{ $t('global.send') }}</span>
            </div>
            <div class="card__link" @click="$emit('onClickDelete', item)">
              <span :class="[item.isDeleteLoading ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-delete']"></span>
              <span class="card__link__label">{{ $t('global.delete') }}</span>
            </div>
          </div>
        </div>
        <!-- CARD_TYPE.MEDICAL_RESIGNATION -->
        <div class="card__content"  v-if="item.type === CARD_TYPE.MEDICAL_RESIGNATION">
          <div class="card__header">
            <div class="card__title-container">
              <span class="card__title">{{ $t('menu.healthMonitoring.medicalResignation') }}</span>
              <span class="card__date">{{item.resignation.createdAt | moment}}</span>
            </div>
          </div>
          <div class="card__columns-container">
            <div class="card__first-column">
              <div class="card__group">
                <p class="card__label">{{ $t('medicalResignation.resignationTime') }}</p>
                <p class="card__data card__data--xl">{{ $t('form.from') }} {{resignationFromDate(item.resignation) | moment }} {{ $t('form.to') }} {{ item.resignation.validAt | moment }}</p>
              </div>
              <span class="card__separator"></span>
              <div class="card__group">
                <p class="card__label">{{ $t('generalInfo.workplace') }}</p>
                <p class="card__data card__data--xl">{{ item.laborRelation.jobName }}</p>
              </div>
              <div class="card__group card__group--space-between">
                <div class="flex direction-column padding-right-10">
                  <p class="card__data card__data--xl primary-dark-color">{{ item.laborRelation.clientName }}</p>
                  <p class="card__label">{{ item.laborRelation.workplaceName }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card__links-container" v-if="true"><!-- v-if="item.uuid" -->
            <div class="card__link" @click="$emit('onClickPreview', item)">
              <span class="card__link__icon icon icon-show"></span>
              <span class="card__link__label">{{ $t('global.preview') }}</span>
            </div>
            <div class="card__link" @click="openDownloadResignation(item, index)">
              <span :class="[item.isLoading ? 'card__link__icon icon loader-sppinner' : 'card__link__icon icon icon-download']"></span>
              <span class="card__link__label">{{ $t('global.download') }}</span>
            </div>
            <div class="card__link" @click="$emit('onClickShareLink', item)">
              <span class="card__link__icon icon icon-mail"></span>
              <span class="card__link__label">{{ $t('global.send') }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- LAYOUT -->
    </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import documentService from '@/services/documentService.js'
import Toast from '@/components/toasts/Toast.vue'
import consentService from '@/services/consentService.js'
import * as _files from '@/_helpers/files.helper.js'

export default {
  name: 'CardList',
  components: { Toast },
  props: ['data'],
  downloadLink: null,
  data () {
    return {
      isLoading: [],
      toastParams: null,
      isToastVisible: false,
      CARD_TYPE: Vue.CONSTANTS.CARD_TYPE,
      ACTIVITY_STATE: Vue.CONSTANTS.ACTIVITY_STATE,
      DEVICES: Vue.CONSTANTS.DEVICES,
      PHYSICAL_EXAMINATION_TYPE: Vue.CONSTANTS.PHYSICAL_EXAMINATION_TYPE
    }
  },
  computed: {
    ...mapGetters(['getCurrentDevice'])
  },
  mounted () {
    console.log(this.data)
    this.data.forEach(element => {
      this.isLoading.push(false)
    })
  },
  methods: {
    getDocumentType (type) {
      switch (type) {
        case this.CARD_TYPE.PHYSICAL_EXAMINATION:
          return 44
        case this.CARD_TYPE.FITNESS_SUMMARY:
          return 45
        case this.CARD_TYPE.TRAINING:
          return 47
        case this.CARD_TYPE.ANALYTICS:
          return 102
        case this.CARD_TYPE.PERFORMED_TESTS:
          return 104
        case this.CARD_TYPE.OTHER_DOCUMENTATION:
          return 103
        default:
          return 9
      }
    },
    getCallLabel (phone) {
      if (this.getCurrentDevice === this.DEVICES.MOBILE) {
        return this.$t('global.call')
      } else {
        return phone
      }
    },
    openCancelAppointment: function (data) {
      this.$emit('onClickCancelAppointment', data)
    },
    openConsentForm: function (data) {
      if (!data.medicalExaminationId) this.$emit('onClickOpenConsentForm', data)
    },
    openDownloadResignation: function (item, index) {
      if (item.isLoading) return
      this.$set(this.isLoading, index, true)
      this.$emit('onClickDownload', item)
    },
    openDownloadConsentForm: function (data, index) {
      // this.$emit('onClickDownloadConsentForm', data)
      if (this.isLoading[index]) return
      this.$set(this.isLoading, index, true)
      consentService.downloadAcceptedConsent(data.id)
        .then((response) => {
          console.log(response)
          _files.downloadPDF(response.data.archivo64, response.data.fileName)
        })
        .catch(() => {
          const type = 'danger'
          const message = this.$t('errorMessage.generic')
          this.cancelAppointmentToast(message, type)
        })
        .finally(() => {
          this.$set(this.isLoading, index, false)
        })
    },
    openShareModal: function (file, type) {
      const data = {
        uuid: file,
        documentType: this.getDocumentType(type)
      }
      this.$emit('onClickShareLink', data)
    },
    openDownload: function (file, type, index) {
      if (this.isLoading[index]) return
      this.$set(this.isLoading, index, true)
      documentService.getDocument(file, this.getDocumentType(type))
        .then((response) => {
          // console.log()
          _files.downloadPDF(response.data.fichero, response.data.nombreFichero)
        })
        .catch(() => {
          // console.log()
          this.isToastVisible = true
          this.toastParams = {
            type: 'danger',
            text: this.$t('errorMessage.generic'),
            time: 5000
          }
          setTimeout(() => {
            this.isToastVisible = false
          }, 4000)
        })
        .finally(() => {
          this.$set(this.isLoading, index, false)
          // console.log()
        })
    },
    getStateCard: function (item) {
      if (item) {
        // // console.log()
        const state = ({}).hasOwnProperty.call(item, 'state')
        if (state) {
          switch (item.state) {
            case this.ACTIVITY_STATE.CURRENT:
              return this.$i18n.t('generalInfo.currentState')
            case this.ACTIVITY_STATE.EXPIRE_SOON:
              return this.$i18n.t('generalInfo.expireSoonState', { leftDays: this.getLeftDaysToExpire(item.dates.endDate) })
            case this.ACTIVITY_STATE.EXPIRED:
              return this.$i18n.t('generalInfo.expiredState')
            default:
              return ''
          }
        } else {
          return ''
        }
      }
    },
    getLeftDaysToExpire: function (endDate) {
      return -(moment().diff(moment(endDate), 'days'))
    },
    getStateClass: function (item) {
      if (item) {
        const state = ({}).hasOwnProperty.call(item, 'state')
        if (state) {
          switch (item.state) {
            case 1:
              return 'tag--success'
            case 2:
              return 'tag--warning'
            case 3:
              return 'tag--danger'
            default:
              return ''
          }
        } else {
          return ''
        }
      }
    },
    getIcon (type) {
      switch (type) {
        case this.CARD_TYPE.PHYSICAL_EXAMINATION:
          return 'icon-physical-examination'
        case this.CARD_TYPE.FITNESS_SUMMARY:
          return 'icon-fitness-summary'
        case this.CARD_TYPE.OTHER_DOCUMENTATION:
          return 'icon-other-documentation'
        case this.CARD_TYPE.MEDICAL_RESIGNATION:
          return 'icon-waive'
        case this.CARD_TYPE.ANALYTICS:
          return 'icon-analytics'
        case this.CARD_TYPE.PERFORMED_TESTS:
          return 'icon-tests'
        case this.CARD_TYPE.APPOINMENTS:
          return 'icon-appointment'
        case this.CARD_TYPE.TRAINING:
          return 'icon-training'
        case this.CARD_TYPE.WORKPLACE:
          return 'icon-chair bg-light-blue'
        case this.CARD_TYPE.EMPLOYMENT_HISTORY:
          return 'icon-briefcase bg-light-blue'
        case this.CARD_TYPE.RISKS_EVALUATION:
          return 'icon-helmet bg-light-blue'
        case this.CARD_TYPE.UPLOADED_DOCUMENTATION:
          return 'icon-other-documentation'
        case this.CARD_TYPE.ICPT:
          return 'icon-info-1'
        default:
          return ''
      }
    },
    getBGIcon (type) {
      switch (type) {
        case this.CARD_TYPE.PHYSICAL_EXAMINATION:
          return ''
        case this.CARD_TYPE.FITNESS_SUMMARY:
          return ''
        case this.CARD_TYPE.OTHER_DOCUMENTATION:
          return ''
        case this.CARD_TYPE.ANALYTICS:
          return ''
        case this.CARD_TYPE.PERFORMED_TESTS:
          return ''
        case this.CARD_TYPE.APPOINMENTS:
          return ''
        case this.CARD_TYPE.TRAINING:
          return 'bg-light-blue'
        case this.CARD_TYPE.WORKPLACE:
          return 'bg-light-blue'
        case this.CARD_TYPE.EMPLOYMENT_HISTORY:
          return 'bg-light-blue'
        case this.CARD_TYPE.RISKS_EVALUATION:
          return 'bg-light-blue'
        case this.CARD_TYPE.UPLOADED_DOCUMENTATION:
          return ''
        case this.CARD_TYPE.ICPT:
          return 'bg-light-blue'
        default:
          return ''
      }
    },
    getCompanyLogo (logo) {
      const images = require.context('../../assets/images/', false, /\.png$/)
      return images('./' + logo)
    },
    openPreview: function (item, type) {
      const data = {
        uuid: item,
        documentType: this.getDocumentType(type)
      }
      this.$emit('onClickPreview', data)
    },
    getActiveFrom (date) {
      const result = 'Activo desde el ' + moment(date).format('DD MMM YYYY')
      return result
    },
    getFilteredProtocols: function (protocols) {
      console.log(protocols)
      const p = []
      protocols.forEach(protocol => {
        protocol.protocolMasters.forEach(protocolMaster => {
          if (protocolMaster.languageCode === this.$i18n.locale) {
            p.push(protocolMaster)
          }
        })
      })
      console.log(p)
      return p
    },
    getFilteredAptitude: function (aptitudes) {
      return aptitudes.filter(aptitude => {
        if (aptitude.id.languageCode === this.$i18n.locale) {
          return aptitude
        }
      })
    },
    getLocationLink (medicalCenter) {
      if (!medicalCenter) return null
      if (medicalCenter.latitude === '0.0' && medicalCenter.longitude === '0.0') {
        return null
      } else {
        return `http://www.google.com/maps/place/${medicalCenter.latitude},${medicalCenter.longitude}`
      }
    },
    deleteDocument () {
      console.log('DELETE document')
    },
    resignationFromDate: function (resignation) {
      console.log(resignation)
      return moment(resignation.validAt).subtract(resignation.periodicity, 'months')
    }
  },
  filters: {
    moment: function (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('DD MMM  YYYY')
    },
    moment2: function (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('LL') + ', ' + moment(date).format('dddd')
    },
    hour: function (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('LT')
    }
  }
}
</script>

<style scoped>
.loader-sppinner {
  border: 2px solid rgb(187 189 188);
  border-left-color: transparent;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
}

.no-boder-bottom {
  border-bottom: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

</style>
