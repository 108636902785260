<template>
  <div class="">
    <!-- <Toast :params="toastParams" v-if="isToastVisible"/>
    <DocModal :data="docModalData" @onClose="closeDocModal()" v-if="isDocModalVisible" />
    <Modal :params="shareModalParams" v-if="isModalVisible" @onClose="closeModal()" @onSendFile="sendFile($event)" /> -->
    <h1>{{ $t('menu.technicalPrevention.openClassroomCalendar') }}</h1>
    <!-- <Select :data="provinces" :itemLabel="'label'" :itemSelected="provinces[0]" /> -->
    <!-- <NoResults :type="CARD_TYPE.PHYSICAL_EXAMINATION" v-if="recentActivities.length === 0 && !skeletonIsVisible" /> -->
    <!-- <CardList :data="recentActivities" @onClickPreview="openPreview($event)" @onClickShareLink="openModal($event)"   v-if="recentActivities.length > 0"/> -->
      <div class="box-message">
        <span class="box-message__text">{{ $t('openClassroomCalendar.message') }}</span>
      </div>
      <div class="open-class-calendar__filter__container margin-top-20">
        <Select :data="provinces" :itemLabel="'name'" :itemSelected="selectedProvince" @onSelect="setProvince($event)" class="open-class-calendar__filter__input " />
        <Select :data="getQuartersFiltered" :placeholder="$t('global.select')" :itemSelected="getSelectedQuarter"  @onSelect="setQuarter($event)" class="open-class-calendar__filter__input " />
        <input type="text" class="open-class-calendar__filter__input input" v-model="searchInput" :placeholder="$t('openClassroomCalendar.course')">
      </div>
      <!-- <p class="margin-top-20">Entre entre Enero y Marzo tienes <span class="bold">{{coursesFiltrered.length}}</span> cursos disponibles:</p> -->
      <p class="margin-top-20" v-html="getBetweenMonths"></p>
      <SkeletonCard v-if="isLoading" :size="'small'"/>
      <div class="open-class-calendar__list margin-top-20">
        <div class="open-class-calendar__item" :class="{'open-class-calendar__item--selected' : course.selected}" v-for="(course, index) in coursesFiltrered" :key="index" @click="selectCourse(course)">
          <span class="open-class-calendar__item__close icon icon-close"  v-if="getCurrentDevice === DEVICES.MOBILE"></span>
          <div class="open-class-calendar__header">
            <span class="open-class-calendar__title">{{ getCourseName(course) }}</span>
            <div class="open-class-calendar__time">
              <span class="open-class-calendar__time__icon icon icon-clock"></span>
              <!--<span class="open-class-calendar__time__label">{{ course.time }}</span>-->
              <span class="open-class-calendar__time__label">{{ $tc('openClassroomCalendar.hours', getDuration(course)) }}</span>
            </div>
            <div class="open-class-calendar__courses-number">
              <span class="open-class-calendar__courses-number__label">{{ $tc('openClassroomCalendar.courses', getCoursesLength(course)) }}</span>
            </div>
          </div>
          <div class="open-class-calendar__courses__list">
            <div class="open-class-calendar__courses__item" v-for="(location, index) in getLocationsFromCourse(course)" :key="index">
              <span class="open-class-calendar__courses__data bold">{{ location.day | moment }}</span>
              <span class="open-class-calendar__courses__data">{{ location.hour }}</span>
              <div class="open-class-calendar__courses__data">
                <div class="tag tag--virtual-class" v-if="isVirtualClass(location.address)">
                  <span class="tag__icon icon icon-laptop"></span>
                  <span class="tag__label">{{ $t('openClassroomCalendar.virtualClass') }}</span>
                </div>
                <span  v-if="!isVirtualClass(location.address)">{{ location.address }}</span>
              </div>
              <span class="open-class-calendar__courses__data">{{ location.city }}</span>
            </div>
          </div>
        </div>
      </div>
   </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Select from '@/components/ui/Select.vue'
import coursesService from '@/services/coursesService.js'
import moment from 'moment'
import SkeletonCard from '@/components/commons/SkeletonCard.vue'
export default {
  name: 'OpenClassroomCalendar',
  components: { Select, SkeletonCard },
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES,
      searchInput: '',
      selectedProvince: null,
      selectedQuarter: null,
      provinceList: null,
      courses: [],
      isLoading: false,
      provinces: [],
      selectedQuarterIndex: null,
      quarters: [
        { name: 'Enero a Marzo' },
        { name: 'Abril a Junio' },
        { name: 'Julio a Septiembre' },
        { name: 'Octubre a Diciembre' }
      ],
      numCourses: 0
    }
  },
  mounted () {
    // this.getProvinces()
    if (!this.selectedQuarter) {
      this.selectedQuarter = this.getQuartersFiltered[0]
      this.selectedQuarterIndex = 0
    }
    // console.log()
    if (this.getProvinces !== null) {
      this.provinces = this.getProvinces
      this.setInitialProvince()
    }
    if (this.getProvincesStatus === 'stopped') this.$store.dispatch('fetchApi')
    // if (this.getProvincesStatus === 'pending') this.skeletonIsVisible = true
  },
  computed: {
    ...mapGetters(['getCurrentDevice', 'getCurrentUser', 'getProvincesStatus', 'getProvinces', 'getLocale']),
    coursesFiltrered () {
      return this.courses.filter(course => {
        return course.name.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1
      })
    },
    getSelectedQuarter () {
      return this.getQuarters[this.selectedQuarterIndex]
    },
    getQuarters () {
      return [
        {
          name: this.$t('openClassroomCalendar.quarters.firstQuarter'),
          disabled: moment().month() > moment().month(2).month(),
          monthIni: this.$t('openClassroomCalendar.months.january'),
          monthFin: this.$t('openClassroomCalendar.months.march')
        },
        {
          name: this.$t('openClassroomCalendar.quarters.secondQuarter'),
          disabled: moment().month() > moment().month(5).month(),
          monthIni: this.$t('openClassroomCalendar.months.april'),
          monthFin: this.$t('openClassroomCalendar.months.june')
        },
        {
          name: this.$t('openClassroomCalendar.quarters.thirdQuarter'),
          disabled: moment().month() > moment().month(8).month(),
          monthIni: this.$t('openClassroomCalendar.months.july'),
          monthFin: this.$t('openClassroomCalendar.months.september')
        },
        {
          name: this.$t('openClassroomCalendar.quarters.fourthQuarter'),
          disabled: false,
          monthIni: this.$t('openClassroomCalendar.months.october'),
          monthFin: this.$t('openClassroomCalendar.months.december')
        }
      ]
    },
    getQuartersFiltered () {
      return this.getQuarters.filter((x) => {
        if (!x.disabled) return x
      })
    },
    getBetweenMonths () {
      if (this.selectedQuarter) {
        return this.$t('openClassroomCalendar.between',
          {
            monthIni: this.getSelectedQuarter.monthIni,
            conjunction: this.$t('openClassroomCalendar.conjunction'),
            monthFin: this.getSelectedQuarter.monthFin,
            n: this.coursesFiltrered.length
          })
      } else {
        return ''
      }
    }
  },
  methods: {
    /*
    getProvinces () {
      userService.getProvinces()
        .then((response) => {
          // console.log()
          this.provinces = response.data
          this.setInitialProvince()
        })
        .catch(() => {
          // console.log()
        })
        .finally(() => {
          // console.log()
        })
    },
    */
    getCourseName (course) {
      console.log(course)
      if (this.$i18n.locale === 'es') {
        return course.name
      } else {
        const a = course.translations.find(translation => translation.id.languageCode === this.$i18n.locale)
        if (a) {
          return a.name
        } else {
          return course.name
        }
      }
    },
    getCoursesLength (course) {
      return course.locations.length
    },
    getDuration (course) {
      return Math.floor(course.locations[0].duration / 3600)
    },
    selectCourse (selectedCourse) {
      // console.log()
      // console.log()
      this.courses.forEach(course => {
        if (course === selectedCourse) {
          // console.log()
          course.selected = !course.selected
        } else {
          course.selected = false
        }
      })
    },
    isVirtualClass (address) {
      return address === this.$t('openClassroomCalendar.onlineTraining') || address === this.$t('openClassroomCalendar.virtualClassTraining')
    },
    setProvince (province) {
      this.selectedProvince = province
      // console.log()
      // this.selectedQuarter = null
      this.setQuarter(this.selectedQuarter)
      /*
      const index = this.getQuarters.map(function (o) {
        return o.name
      }).indexOf(this.selectedQuarter.name)
      this.selectedQuarterIndex = index
      // console.log()
      this.getCourses(index)
      */
    },
    setQuarter (quarter) {
      this.courses = []
      this.selectedQuarter = quarter
      // console.log()
      const index = this.getQuarters.map(function (o) {
        return o.name
      }).indexOf(this.selectedQuarter.name)
      this.selectedQuarterIndex = index
      // console.log()
      this.getCourses(index)
    },
    getLocationsFromCourse (course) {
      // console.log()
      if (this.selectedProvince) {
        // console.log()
        return course.locations.filter(location => location.province.toLowerCase().indexOf(this.selectedProvince.name.toLowerCase()) > -1)
      } else {
        return course.locations
      }
    },
    getCourses (dateCode) {
      this.isLoading = true
      coursesService.getCourses(this.selectedProvince.id, dateCode)
        .then((response) => {
          this.isLoading = false
          this.courses = response.data
          this.courses.forEach(course => {
            course.locations.forEach(location => {
              location.day = moment(location.day, 'dd/MM/yyyy').toDate()
            })
          })
          this.courses.forEach(course => {
            this.$set(course, 'selected', false)
          })
        })
        .catch(() => {
          // console.log()
        })
        .finally(() => {
          // console.log()
        })
    },
    setInitialProvince () {
      this.provinces.forEach(province => {
        if (province.id === this.getCurrentUser.provinceId) {
          this.selectedProvince = province
        }
      })
      if (this.selectedQuarter) this.setQuarter(this.selectedQuarter)
    }
  },
  filters: {
    moment: function (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('DD MMM')
    }
  },
  watch: {
    getProvincesStatus: {
      handler (newVal, oldVal) {
        if (newVal === 'success') {
          this.provinces = this.getProvinces
          this.setInitialProvince()
        }
        /*
        if (oldVal === 'stopped' && newVal === 'pending') {
          this.skeletonIsVisible = true
        }
        */
      },
      deep: true
    },
    getLocale: {
      handler (newVal, oldVal) {
        // console.log()
        // this.quarters = [
        //   { name: moment().locale(this.$i18n.locale).format('MMMM') },
        //   { name: 'Abril a Junio' },
        //   { name: 'Julio a Septiembre' },
        //   { name: 'Octubre a Diciembre' }
        // ]
      },
      deep: true
    }
  }
}
</script>
