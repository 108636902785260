<template>
  <div>
    <div class="welcome-view__container" v-if="!finallyLoad && isModalVisible">
      <div class="welcome-view__image-container">
        <WelcomeImage />
      </div>
      <div class="welcome-view__form-container" v-if="isModalVisible">
        <!-- MODAL LEGAL TEXTS -->
        <LegalModal :texts="computedServiceTerms" :mode="mode" :type="legalContent" :isLoading="isLoading" @onClose="showPrevingTerms(false)" @onAccept="acceptTerms($event)" v-if="isModalVisible && isShowPrevingTermsVisible && legalContent === 'TERMS'"/>
        <LegalModal :texts="computedPrivacyTerms" :mode="mode" :type="legalContent" :isLoading="isLoading" @onClose="showPrevingTerms(false)" @onAccept="acceptTerms($event)" v-if="isModalVisible && isShowPrevingTermsVisible && legalContent === 'PRIVACY'"/>
      </div>
    </div>
    <div class="spinner__init">
      <Spinner
        v-if="!(finallyLoad && finallyEmploymentHistory && finallyProvinces)"
        :styles="'stroke: #d7d7d7;'"
      />
    </div>
    <div v-if="finallyLoad && finallyEmploymentHistory && finallyProvinces">
      <div class="section__header">
        <div>
          <Button
            :icon="'icon-next icon-rotate-180'"
            classes="button button--link button--positive"
            style="border: none; display: inline;"
            @click.native="exitForm"
          />
          <h3 class="header-title" style="display: inline;">{{ $t('consentForm.title_1') }} {{ $t('consentForm.title_2') }}</h3>
        </div>
        <span v-if="activeStepId > 1" class="paginator" style="color: #B3B3B3;font-size: 1.2em; text-align: right;">Paso {{ activeStepId -1 }} de 3</span>
      </div>
      <div class="section__container">
        <!-- DEUDA ________________________________________________________
              Selector de provincia: multiselect.
              Checkboxes y Radios con dato preestablecido.
              Flechitas de los iconos a la derecha.
        -->
        <!-- TODO _________________________________________________________
              NAVEGACIÓN
                - Scroll en vertical.
                - Revisar maquetación en la versión móvil.
                - se mantiene la cabecera
                - Botón abajo a 'Volver a Preving contigo' a la sección 'Mis citas'
                - y esta cita debería de eliminar el aviso de 'consentimiento pendiente'.
              Validación de campos:
                - Inputs date, email, tlf... y mensajes de error
              Gestionar el disabled de los botones.
              RadioButtons: que puedan recibir datos preestablecidos.
                - Situación laboal y sensibilidad premarcados en Activo y No.
              Crear un componente para 'little-advise':
                - Cuando se acepta el check debería quitarse el fondo.
                - v-model que acepte términos y active el botón siguiente.
                - prop que determine si todo el aviso hace check.
              Último button:
                - Que envíe al servidor, pero por ahora que muestre el data.
                - Que active un loader hasta que se reciba el PDF.
                - Mostraremos un visor pdf estándar, que ya han usado.
        -->
        <div class="section__content section__content--m">
          <!-- STEP 0 -->
          <Tabs
            v-if="activeStepId === 1"
          >
            <div v-html="$t('consentForm.intro')" style="margin-top: 20px"/>

            <InformationCard title="consentForm.basicInformation">
              <p>{{ $t('consentForm.responsable') }}</p>
              <p>{{ responsableCompany }}</p>
              <!-- <p>SPA</p> -->
              <p>{{ $t('consentForm.purpose') }}</p>
              <p>{{ $t('consentForm.purposeAdd') }}</p>
              <p>{{ $t('consentForm.legitimation') }}</p>
              <p>{{ $t('consentForm.legitimationAdd') }}</p>
              <p>{{ $t('consentForm.receiver') }}</p>
              <p>{{ $t('consentForm.receiverAdd') }}</p>
              <p>{{ $t('consentForm.rights') }}</p>
              <p>{{ $t('consentForm.rightsAdd') }}</p>
              <p>{{ $t('consentForm.additionalInfo') }}</p>
              <p v-html="$t('consentForm.additionalInfoAdd')" />
            </InformationCard>
            <div class="button-content">
              <Button
                :label="$t('consentForm.start')"
                :icon="'icon-next icon-rotate-90'"
                classes="button button--cta"
                @click.native="nextStep(true, false)"
                :disabledButton="isLoading || isEmploymentHistoryLoading || isProvincesLoading"
                :isLoading="isLoading || isEmploymentHistoryLoading || isProvincesLoading"
              />
            </div>
          </Tabs>
          <!-- STEP 0.5 -->
          <div v-if="activeStepId >= 2" id="content-tabs">
            <ol id="label-tags">
              <li id="figure" :class="[activeStepId === 2 ? 'active-li' : '' ]" @click="nextStep(2, false)">1. {{ $t('consentForm.label1')}}</li>
              <li id="figure" :class="[activeStepId === 3 ? 'active-li' : '' ]" @click="nextStep(3, stepOneDisabled)">2. {{ $t('consentForm.label2')}}</li>
              <li id="figure" :class="[activeStepId === 4 ? 'active-li' : '' ]" @click="nextStep(4, stepOneDisabled)">3. {{ $t('consentForm.label3')}}</li>
            </ol>
          </div>
          <!-- STEP 1 GENERAL INFORMATION -->
          <Tabs
            v-if="activeStepId === 2"
          >
            <div class="button-content">
              <Button
                :label="$t('global.previous')"
                :icon="'icon-next icon-rotate-270'"
                classes="button button--link"
                @click.native="nextStep(false, false)"
              />
            </div>
            <p>{{ $t('consentForm.checkYourData') }}</p>
            <h4>{{ $t('consentForm.healthExamJob') }}</h4>
            <InputLabel
              v-model="healthExamDate"
              formElement="healthExamDate"
              inputType="text"
              disabled
              textLabel="consentForm.healthExamDate"
            />
            <InputLabel
              v-model="company"
              formElement="company"
              inputType="text"
              disabled
              textLabel="consentForm.company"
            />
            <InputLabel
              v-model="job"
              formElement="job"
              inputType="text"
              disabled
              textLabel="consentForm.job"
            />
            <InputLabel
              v-model="workplace"
              formElement="workplace"
              inputType="text"
              disabled
              textLabel="consentForm.workplace"
            />
            <InputLabel
              v-model="location"
              formElement="location"
              inputType="text"
              disabled
              textLabel="consentForm.location"
            />
            <h4>{{ $t('consentForm.personalData') }}</h4>
            <InputLabel
              v-model="nameSurname"
              formElement="nameSurname"
              inputType="text"
              disabled
              textLabel="consentForm.nameSurname"
            />
            <InputLabel
              v-model="personalId"
              formElement="personalId"
              inputType="text"
              disabled
              textLabel="consentForm.personalId"
            />
            <InputLabel
              v-model="dateOfBirth"
              formElement="dateOfBirth"
              inputType="date"
              :requiredInput="true"
              :disabled="disabledDateofBirth"
              textLabel="consentForm.dateOfBirth"
            />
            <InputLabel
              v-model="email"
              formElement="email"
              inputType="email"
              disabled
              textLabel="consentForm.email"
            />
            <InputLabel
              v-model="phoneNumber"
              formElement="phoneNumber"
              inputType="text"
              :requiredInput="true"
              textLabel="consentForm.phoneNumber"
            />
            <!-- TODO campo 'active' checkeado desde inicio -->
            <RadioButtonsLabel
              v-model="offWorkStatus"
              textLabel="consentForm.employmentStatus"
              groupName="employmentStatus"
              :requiredInput="true"
              :radioOptions="[
                { label: 'consentForm.employmentStatus_active', forId: 'active', value: false },
                { label: 'consentForm.employmentStatus_leave', forId: 'leave', value: true }
              ]"
            /><!-- @change="employmentStatus" -->
            <!-- TODO campo 'no' checkeado desde inicio -->
            <RadioButtonsLabel
              v-model="sensibility"
              :requiredInput="true"
              textLabel="consentForm.sensitivityWorker"
              groupName="sensitivityWorker"
            /><!-- @change="sensitivityWorker" -->
            <RadioButtonsLabel
              v-if="sensibility === true || sensibility === null"
              v-model="sensibilityTrue"
              @click.native="checkSensibility()"
              groupName="sensitivityWorker_opt"
              :radioOptions="[
                { label: 'consentForm.sensitivityWorker_opt.maternity', forId: 'maternity', value: $t('consentForm.sensitivityWorker_opt.maternity') },
                { label: 'consentForm.sensitivityWorker_opt.younger', forId: 'younger', value: $t('consentForm.sensitivityWorker_opt.younger') },
                { label: 'consentForm.sensitivityWorker_opt.lactation', forId: 'lactation', value: $t('consentForm.sensitivityWorker_opt.lactation') },
                { label: 'consentForm.sensitivityWorker_opt.disability', forId: 'disability', value: $t('consentForm.sensitivityWorker_opt.disability') },
                { label: 'consentForm.sensitivityWorker_opt.others', forId: 'others', value: $t('consentForm.sensitivityWorker_opt.others') }
              ]"
            />
            <InputLabel
              v-if="sensitivityWorker_opt === 'others'"
              v-model="sensitivityWorker_opt_other"
              formElement="sensitivityWorker_opt_other"
              inputType="textarea"
              :maxlength="80"
              :placeholder="$t('global.typeHere')"
              textLabel="consentForm.sensitivityWorker_opt.others_label"
            />
            <h4>{{ $t('consentForm.habitualResidence') }}</h4>
            <InputLabel
              v-model="address"
              formElement="address"
              inputType="text"
              textLabel="consentForm.address"
            />
            <div class="form-group form-group--inline margin-top-20">
              <label class="form-group__label" style="width: 200px;">{{ $t('myProfile.province') }}</label>
              <div class="form-group__data">
                <Select
                  :itemLabel="'label'"
                  :data="provinces"
                  :class="'form-group__data'"
                  :itemSelected="provinceSelected"
                  :placeholder="'Selecciona...'"
                  @onSelect="setProvince($event)"
                />
              </div>
            </div>
            <div class="form-group form-group--inline margin-top-20">
              <label class="form-group__label" style="width: 200px;">{{ $t('myProfile.city') }}</label>
              <div class="form-group__data">
                <Select
                  :data="cities"
                  :itemSelected="citySelected"
                  :placeholder="'Selecciona...'"
                  @onSelect="setCity($event)"
                />
              </div>
            </div>
            <InputLabel
              v-model="postalCode"
              formElement="postalCode"
              inputType="text"
              textLabel="consentForm.postalCode"
            />
            <div class="button-content">
              <Button
                :label="$t('global.next')"
                :icon="'icon-next icon-rotate-90'"
                classes="button button--cta"
                :disabledButton="stepOneDisabled"
                @click.native="nextStep(true, false)"
              />
              <Button
                :label="$t('form.exitWithoutSaving')"
                classes="button button--link"
                @click.native="exitForm"
              />
            </div>
          </Tabs>
          <!-- STEP 2 ACCEPTANCE -->
          <Tabs
            v-if="activeStepId === 3"
          >
            <div class="button-content">
              <Button
                :label="$t('global.previous')"
                :icon="'icon-next icon-rotate-270'"
                classes="button button--link"
                @click.native="nextStep(false, false)"
              />
            </div>
            <h2>{{ $t('consentForm.acceptanceMedicalExamination') }}</h2>
            <div v-html="$t('consentForm.acceptanceText')" class="acceptance-text"/>
            <div class="button-content">
              <Button
                :label="$t('global.acceptContinue')"
                :icon="'icon-next icon-rotate-90'"
                classes="button button--cta"
                @click.native="nextStep(true, false)"
              />
              <Button
                :label="$t('form.exitWithoutSaving')"
                classes="button button--link"
                @click.native="exitForm"
              />
            </div>
          </Tabs>
          <!-- STEP 3 CONSENT FORM -->
          <Tabs
            v-if="activeStepId === 4"
          >
            <div class="button-content">
              <Button
                :label="$t('global.previous')"
                :icon="'icon-next icon-rotate-270'"
                classes="button button--link"
                @click.native="nextStep(false)"
              />
            </div>
            <h2>{{ $t('consentForm.healthQuestionnaire') }}</h2>
            <!-- <div class="little-advise blue" v-if="!previousConsentCompleted"> -->
            <div class="little-advise blue" v-if="appointmentInfo.informedConsent && !previousConsentCompleted">
              <p
                v-html="$t('consentForm.autocompleteQuestion', { lastDateQuestionnaire: lastDateQuestionnaire })"
              />
              <Button
                classes="button"
                :label="$t('form.autocomplete')"
                @click.native="autocompleteForm"
              />
            </div>
            <div v-for="(item, index) in data" :key="index">
              <RadioButtonsLabel
                v-if="item.type === QUESTION_TYPE.RADIO_BOOLEAN || item.type === QUESTION_TYPE.RADIO_BOOLEAN_QUESTION"
                v-model="item.result"
                @input="testInput($event)"
                :requiredInput="item.required"
                :numberLabel="index+1"
                :textLabel="item.description"
                :groupName="item.description"
              />
              <InputLabel
                v-if="(item.result === 'true' || item.result === true) && item.type === QUESTION_TYPE.RADIO_BOOLEAN_QUESTION"
                v-model="item.questionDetails"
                :requiredInput="item.required"
                formElement="medication_text"
                inputType="textarea"
                :maxlength="200"
                :placeholder="$t('global.typeHere')"
                textLabel="consentForm.medication-text"
              />
              <CheckboxesLabel
                v-if="item.type === QUESTION_TYPE.CHECKBOX_MULTIPLE"
                v-model="item.results"
                :numberLabel="index+1"
                :requiredInput="item.required"
                :textLabel="item.description"
                :groupName="item.description"
                :labelOnTop="true"
                :checkboxOptions="item.options"
              />
              <div
                class="form-radio-group boxing-style"
                style="padding:0; grid-template-columns: 100%;padding-top: 25px;display: grid;grid-template-columns: 100%;"
                v-if="item.type === QUESTION_TYPE.TEXT"
              >
                <div class="form-radio-group__header">
                  <div class="card__icon-container">
                    <span class="card__icon">{{ index+1 }}</span>
                  </div>
                  <p class="form-radio">
                    {{ item.description }}
                  </p>
                </div>
                <div style="padding: 0 20px 0 45px;">
                  <InputLabel
                    v-model="item.result"
                    formElement="otherData"
                    inputType="textarea"
                    :maxlength="200"
                    :noLabel="true"
                    :placeholder="$t('global.typeHere')"
                  />
                </div>
              </div>
            </div>
            <div class="little-advise yellow">
              <input
                id="finalDeclaration"
                v-model="finalDeclaration"
                type="checkbox"
                class="final-checkbox"
                name="finalDeclaration"
                value="true"
              >
              <p
                v-html="$t('consentForm.declarationData')"
              />
            </div>
            <div class="button-content">
              <Button
                :label="$t('global.end')"
                classes="button button--cta"
                :disabledButton="!getConsentCompleted"
                @click.native="sendingForm"
                :isLoading="isLoading"
              />
              <Button
                :label="$t('form.exitWithoutSaving')"
                classes="button button--link"
                @click.native="exitForm"
              />
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import Vue from 'vue'
import WelcomeImage from '@/components/welcome/WelcomeImage'
import LegalModal from '@/components/welcome/LegalModal.vue'
import userService from '@/services/userService.js'
import InputLabel from '@/components/commons/InputLabel.vue'
import RadioButtonsLabel from '@/components/commons/RadioButtonsLabel.vue'
import CheckboxesLabel from '@/components/commons/CheckboxesLabel.vue'
import InformationCard from '../commons/InformationCard.vue'
import Tabs from '../commons/Tabs.vue'
import Button from '@/components/ui/Button.vue'
import consentService from '@/services/consentService.js'
import appointmentService from '@/services/appointmentService.js'
import Select from '@/components/ui/Select.vue'
import * as _modules from '@/_helpers/modules.helper.js'
import { mapGetters } from 'vuex'
import Spinner from '@/components/spinners/Spinner.vue'

export default {
  name: 'DirectConsentForm',
  components: { Spinner, LegalModal, WelcomeImage, Select, InputLabel, RadioButtonsLabel, CheckboxesLabel, InformationCard, Tabs, Button },
  data () {
    return {
      appointmentConsents: [],
      finallyEmploymentHistory: false,
      finallyProvinces: false,
      legalContent: null,
      serviceTerms: null,
      mode: null,
      serviceTermsi18n: null,
      privacyTerms: null,
      privacyTermsi18n: null,
      text: null,
      acceptedTerms: {
        terms: null,
        privacy: null,
        cookies: null
      },
      isShowPrevingTermsVisible: false,
      isModalVisible: null,
      finallyLoad: false,
      isEmploymentHistoryLoading: true,
      isProvincesLoading: true,
      previousConsent: null,
      previousConsentCompleted: false,
      isLoading: false,
      basicInformation: {
        title: null,
        responsable: null,
        responsableCompany: null,
        purpose: null,
        purposeAdd: null,
        legitimation: null,
        legitimationAdd: null,
        receiver: null,
        receiverAdd: null,
        rights: null,
        rightsAdd: null,
        additionalInfo: null,
        additionalInfoAdd: null
      },
      userInfo: null,
      acceptanceText: {
        title: null,
        text: null
      },
      consentCompleted: false,
      employmentHistoryData: null,
      myWorkPlace: null,
      sensibility: null,
      sensibilityTrue: null,
      offWorkStatus: null,
      consentForm: null,
      appointmentInfo: null,
      QUESTION_TYPE: Vue.CONSTANTS.CONSENT_FORM.QUESTION_TYPE,
      activeStepId: 1,
      disabledDateofBirth: false,
      consentFormReply: {
        finalDeclaration: null,
        dateOfDeclaration: null
      },
      generalData: {
        healthExamDate: null,
        company: null,
        job: null,
        workplace: null,
        location: null,
        nameSurname: null,
        personalId: null,
        dateOfBirth: null,
        email: null,
        phoneNumber: null,
        finalDeclaration: null,
        dateOfDeclaration: null,
        address: null,
        addressLocation: null,
        addressProvince: null,
        postalCode: null
      },
      data: null,
      provinces: [],
      cities: [],
      citySelected: null,
      provinceSelected: null,
      provinceId: null,
      province: null,
      city: null,
      cityId: null,

      myAppointments: [],
      appointment: null,
      appointmentId: null,
      countCompleted: null
    }
  },
  computed: {
    ...mapGetters(['getLocale', 'getPrivacyTerms', 'getServiceTerms', 'getCurrentUser', 'getEmploymentHistoryStatus', 'getEmploymentHistory', 'getProvincesStatus', 'getProvinces']),
    lastDateQuestionnaire () {
      if (this.appointmentInfo.informedConsent) {
        return this.$options.filters.moment2(this.appointmentInfo.informedConsent.createdAt)
      } else {
        return null
      }
    },
    computedText: {
      get () { return this.text },
      set (value) { this.text = value }
    },
    computedServiceTerms: {
      get () {
        if (this.getServiceTerms) {
          return decodeURIComponent(atob(this.getServiceTerms.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          }).join(''))
        } else {
          return null
        }
      },
      set (value) { return null }
    },
    computedPrivacyTerms: {
      get () { return this.privacyTerms },
      set (value) { this.privacyTerms = value }
    },
    responsableCompany: {
      get () { return this.myWorkPlace.laborRelation.spa },
      set (value) { this.myWorkPlace.laborRelation.spa = value }
    },
    getConsentCompleted () {
      let a = 0
      if (this.data === null) return false
      this.data.filter(x => x.required === true).forEach(element => {
        // console.log(!('result' in element), element)
        console.log(element)
        if (!('result' in element) && !('results' in element)) a += 1
      })
      console.log(a === 0)
      return a === 0 && this.finalDeclaration
    },
    healthExamDate: {
      // get () { return moment(this.appointmentInfo.date).format('YYYY-MM-DD') },
      get () { return moment(this.generalData.healthExamDate).format('YYYY-MM-DD') },
      set (value) { this.generalData.healthExamDate = value }
    },
    company: {
      get () { return this.generalData.company },
      set (value) { this.generalData.company = value }
    },
    job: {
      get () { return this.generalData.job },
      set (value) { this.generalData.job = value }
    },
    workplace: {
      get () { return this.generalData.workplace },
      set (value) { this.generalData.workplace = value }
    },
    location: {
      get () { return this.generalData.location },
      set (value) { this.generalData.location = value }
    },
    nameSurname: {
      get () { return this.generalData.nameSurname },
      set (value) { this.generalData.nameSurname = value }
    },
    personalId: {
      get () { return this.generalData.personalId },
      set (value) { this.generalData.personalId = value }
    },
    dateOfBirth: {
      get () { return moment(this.generalData.dateOfBirth).format('YYYY-MM-DD') },
      set (value) {
        this.generalData.dateOfBirth = value
      }
    },
    email: {
      get () { return this.generalData.email },
      set (value) { this.generalData.email = value }
    },
    phoneNumber: {
      get () { return this.generalData.phoneNumber },
      set (value) { this.generalData.phoneNumber = value }
    },
    employmentStatus: {
      get () { return this.consentFormReply.employmentStatus },
      set (value) { this.consentFormReply.employmentStatus = value }
    },
    sensitivityWorker: {
      get () { return this.consentFormReply.sensitivityWorker },
      set (value) { this.consentFormReply.sensitivityWorker = value }
    },
    sensitivityWorker_opt: {
      get () { return this.consentFormReply.sensitivityWorker_opt },
      set (value) { this.consentFormReply.sensitivityWorker_opt = value }
    },
    sensitivityWorker_opt_other: {
      get () { return this.consentFormReply.sensitivityWorker_opt_other },
      set (value) { this.consentFormReply.sensitivityWorker_opt_other = value }
    },
    address: {
      get () { return this.generalData.address },
      set (value) { this.generalData.address = value }
    },
    addressLocation: {
      get () { return this.generalData.addressLocation },
      set (value) { this.generalData.addressLocation = value }
    },
    addressProvince: {
      get () { return this.generalData.addressProvince },
      set (value) { this.generalData.addressProvince = value }
    },
    postalCode: {
      get () { return this.generalData.postalCode },
      set (value) { this.generalData.postalCode = value }
    },

    otherData: {
      get () { return this.consentFormReply.otherData },
      set (value) { this.consentFormReply.otherData = value }
    },
    finalDeclaration: {
      get () { return this.consentFormReply.finalDeclaration },
      set (value) { this.consentFormReply.finalDeclaration = value }
    },
    stepOneDisabled () {
      if (this.sensibility === true) {
        return !(this.sensibilityTrue !== null && this.offWorkStatus != null)
      } else {
        return !(this.sensibility !== null && this.offWorkStatus != null)
      }
    }
  },
  mounted () {
    if (this.$route.params.uri) {
      this.getUriData(this.$route.params.uri)
    } else {
      // this.$router.push('/login')
      if (this.getPrivacyTerms !== null) {
        this.privacyTermsi18n = this.getPrivacyTerms
        this.setPrivacyTerms()
      }
      if (this.getServiceTerms !== null) {
        this.serviceTermsi18n = this.getServiceTerms
        this.setPrivacyTerms()
      }
    }
  },
  methods: {
    nextStep (step, disabled) {
      if (disabled) return
      // console.log('STEP', step)
      if (step === true || step === false) {
        step ? this.activeStepId++ : this.activeStepId === 0 ? this.activeStepId = 0 : this.activeStepId--
      } else {
        this.activeStepId = step
      }
      window.scrollTo(0, 0)
    },
    exitForm () {
      this.$router.push('/my-appoinments')
    },
    testInput (event) {
      console.log(event)
      console.log('test input')
    },
    checkSensibility () {
      if (this.sensibility === null) {
        this.sensibility = true
      }
    },
    autocompleteForm () {
      console.log(this.data)
      console.log(this.appointmentInfo)
      if (this.appointmentInfo.informedConsent) {
        if (this.consentForm.version === this.appointmentInfo.informedConsent.consentFormVersion.version) {
          this.previousConsent = JSON.parse(this.appointmentInfo.informedConsent.answers)
          this.data = this.previousConsent.questionary
        }
      }
      // this.data.forEach(element => {
      //   const x = this.previousConsent.questionary.find(x => x.label === element.label)
      //   if ('result' in x) element.result = x.result
      //   if (x.results) element.results = x.results
      //   if (x.questionDetails) element.questionDetails = x.questionDetails
      // })
      // this.data = this.previousConsent.questionary
      this.previousConsentCompleted = true
      console.log('Autocompletar formulario')
    },
    sendingForm () {
      this.isLoading = true
      // TODO revisar si se está pasando a data
      this.consentFormReply.dateOfDeclaration = moment().format('YYYY-MM-DD HH:mm:ss')
      console.log('FINALIZAR FORMULARIO', this.data)

      this.acceptanceText.title = this.$t('consentForm.acceptanceMedicalExamination')
      this.acceptanceText.text = this.$t('consentForm.acceptanceText')

      this.basicInformation.title = this.$t('consentForm.basicInformation')
      this.basicInformation.responsable = this.$t('consentForm.responsable')
      this.basicInformation.responsableCompany = this.myWorkPlace.laborRelation.spa
      this.basicInformation.purpose = this.$t('consentForm.purpose')
      this.basicInformation.purposeAdd = this.$t('consentForm.purposeAdd')
      this.basicInformation.legitimation = this.$t('consentForm.legitimation')
      this.basicInformation.legitimationAdd = this.$t('consentForm.legitimationAdd')
      this.basicInformation.receiver = this.$t('consentForm.receiver')
      this.basicInformation.receiverAdd = this.$t('consentForm.receiverAdd')
      this.basicInformation.rights = this.$t('consentForm.rights')
      this.basicInformation.rightsAdd = this.$t('consentForm.rightsAdd')
      this.basicInformation.additionalInfo = this.$t('consentForm.additionalInfo')
      this.basicInformation.additionalInfoAdd = this.$t('consentForm.additionalInfoAdd')

      this.generalData.dateOfBirth = moment(this.generalData.dateOfBirth).toISOString()

      this.generalData.healthExamDate = moment(this.appointmentInfo.date).format('YYYY-MM-DDTHH:mm')

      const resultData = {
        basicInformation: this.basicInformation,
        generalData: this.generalData,
        acceptance: this.acceptanceText,
        questionary: this.data
      }
      const dataSend = {
        cfVersion: this.consentForm.id,
        appointmentId: this.appointmentInfo.id,
        acceptedConsent: true,
        // answers: JSON.stringify(this.data),
        answers: JSON.stringify(resultData),
        offWorkStatus: this.offWorkStatus,
        sensibility: this.sensibility,
        sensibilityTrue: this.sensibilityTrue,
        locale: this.$i18n.locale
      }
      console.log(resultData)
      console.log(dataSend)
      consentService.sendAcceptedConsentUri(dataSend, this.$route.params.uri)
        .then((response) => {
          // refresh user data
          if (this.checkChangesUserData() > 0) {
            this.updateWorkerData()
          } else {
            this.isLoading = false
            this.$router.push('/my-appoinments')
          }
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
        })
    },
    check (event) {
      console.log('check', event)
    },
    getDate: function (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('DD/MM/YYYY')
    },
    getConsentForm: function () {
      consentService.getConsentForm()
        .then((response) => {
          console.log(response)
          this.consentForm = response.data
          const find = response.data.consentFormVersionTranslations.find(x => x.languageCode === this.$i18n.locale)
          this.data = JSON.parse(find.contents)
          if (this.appointmentInfo.informedConsents.length > 0) {
            const result = this.appointmentInfo.informedConsents.filter(x => x.consentFormVersion.id === this.consentVersion).reduce((a, b) => a.createdAt > b.createdAt ? a : b)
            this.appointmentInfo.informedConsent = result
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
        })
    },
    filterData () {
      console.log('filter data')
      let arr = null
      if (this.employmentHistoryData.length > 0) {
        this.employmentHistoryData.forEach(workPlace => {
          console.log(workPlace)
          if (workPlace.laborRelation.endDate === null && workPlace.laborRelation.clientId === this.appointmentInfo.clientId && workPlace.laborRelation.workplaceId === this.appointmentInfo.workplaceId) {
            arr = workPlace
          }
        })
      }
      this.generalData.company = arr.laborRelation.clientName
      this.generalData.job = arr.laborRelation.jobName
      this.generalData.workplace = arr.laborRelation.workplaceName
      this.generalData.location = arr.laborRelation.clientState
      return arr
    },
    checkChangesUserData () {
      let changes = 0
      if (!moment(this.generalData.dateOfBirth, 'YYYY-MM-DD').isSame(moment(this.getCurrentUser.birthDate, 'YYYY-MM-DD'))) {
        console.log(moment(this.generalData.dateOfBirth, 'YYYY-MM-DD').format('YYYY-MM-DD'))
        console.log(moment(this.getCurrentUser.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))
        console.log(moment(this.getCurrentUser.birthDate, 'YYYY-MM-DD').isSame(moment(this.generalData.dateOfBirth, 'YYYY-MM-DD')))
        changes += 1
      }
      if (this.generalData.phoneNumber !== this.getCurrentUser.mobilePhone) {
        console.log(this.generalData.phoneNumber)
        console.log(this.getCurrentUser.mobilePhone)
        changes += 1
      }
      if (this.generalData.address !== this.getCurrentUser.address) {
        console.log(this.generalData.address)
        console.log(this.getCurrentUser.address)
        changes += 1
      }
      if (this.citySelected) {
        if (this.citySelected.id !== this.getCurrentUser.cityId) {
          changes += 1
        }
      }
      if (this.generalData.postalCode !== this.getCurrentUser.cp) {
        console.log(this.generalData.postalCode)
        console.log(this.getCurrentUser.cp)
        changes += 1
      }
      return changes
    },
    updateWorkerData () {
      const data = {
        language: this.getCurrentUser.language,
        phone: this.generalData.phoneNumber,
        email: this.generalData.email,
        cityId: this.citySelected ? this.citySelected.id : this.getCurrentUser.cityId,
        address: this.generalData.address,
        zipCode: this.generalData.postalCode,
        bornDate: moment(this.generalData.dateOfBirth).toISOString()
      }
      console.log(data)
      userService.updateWorkerData(data)
        .then(() => {
          // console.log()
          this.modifyCurrentUser()
          this.isLoading = false
          this.$router.push('/my-appoinments')
        })
        .catch(() => {
          // console.log()
          this.isLoading = false
          this.$router.push('/my-appoinments')
        })
        .finally(() => {
          // console.log()
        })
    },
    modifyCurrentUser () {
      const tempUser = this.getCurrentUser
      tempUser.mobilePhone = this.generalData.phoneNumber
      tempUser.email = this.generalData.email
      tempUser.address = this.generalData.address
      tempUser.cp = this.generalData.postalCode
      tempUser.birthDate = this.generalData.dateOfBirth
      this.$store.commit('setCurrentUser', tempUser)
    },
    setInitialProvince () {
      if (this.province) {
        this.provinces.forEach(province => {
          if (province.name.toLowerCase() === this.province.toLowerCase()) {
            this.provinceSelected = province
          }
        })
      }
    },
    setProvince: function (province) {
      this.provinceSelected = province
      // console.log()
      this.cities = []
      this.citySelected = null
      this.getCities(province.id)
    },
    setCity: function (city) {
      this.citySelected = city
    },
    getCities (provinceId) {
      userService.getCities(provinceId)
        .then((response) => {
          // console.log()
          response.data.forEach(city => {
            this.cities.push({ id: city.id, name: city.name, selected: false })
          })
          this.setInitialCity()
          // console.log()
        })
        .catch(() => {
          // console.log()
        })
        .finally(() => {
          // console.log()
        })
    },
    setInitialCity () {
      this.cities.forEach(city => {
        if (city.id === this.cityId) {
          this.citySelected = city
        }
      })
    },
    initialize (appointment) {
      console.log('appointment', appointment)
      this.appointmentInfo = appointment
      // if (this.appointmentInfo.informedConsents.length > 0) {
      //   const result = this.appointmentInfo.informedConsents.reduce((a, b) => a.createdAt > b.createdAt ? a : b)
      //   this.appointmentInfo.informedConsent = result
      // }
      console.log(this.appointmentInfo)
      if (this.appointmentInfo !== null && this.appointmentInfo !== undefined) this.healthExamDate = (this.appointmentInfo.date)
      if (this.consentFormReply.dateOfBirth) {
        this.disabledDateofBirth = true
      }
      this.userInfo = this.getCurrentUser
      this.generalData.nameSurname = this.userInfo.name + ' ' + this.userInfo.surname
      this.generalData.personalId = this.userInfo.dni
      this.generalData.dateOfBirth = this.userInfo.birthDate
      this.generalData.email = this.userInfo.email
      this.generalData.phoneNumber = this.userInfo.mobilePhone
      this.generalData.address = this.userInfo.address
      this.generalData.addressLocation = this.userInfo.city
      this.generalData.addressProvince = this.userInfo.province
      this.generalData.postalCode = this.userInfo.cp
      this.provinceId = this.userInfo.provinceId
      this.province = this.userInfo.province
      this.city = this.userInfo.city
      this.cityId = this.userInfo.cityId
      this.getConsentForm()
    },
    getMyAppointments () {
      return appointmentService.getMyAppointments()
        .then((response) => {
          this.$store.commit('setAppointments', response.data)
          sessionStorage.setItem('_appointments', response.data)
          this.filterAppointments()
        })
        .catch(() => {
          // console.log()
        })
    },
    filterAppointments () {
      // this.myAppointmentsData = _modules.getAppointments()
      // // console.log()
      // this.myAppointmentsData.forEach(element => {
      //   const now = moment().format('YYYYMMDD')
      //   if (now >= moment(element.date).format('YYYYMMDD')) element.expired = true
      //   element.type = 6
      //   element.reasons.forEach(reason => {
      //     if (reason.id.languageCode === 'es') {
      //       element.reason = reason.name
      //       element.reasonId = reason.id.itemId
      //     }
      //   })
      //   element.types.forEach(typeTitle => {
      //     if (typeTitle.id.languageCode === 'es') {
      //       element.typeTitle = typeTitle.name
      //     }
      //   })
      //   this.myAppointments.push(element)
      // })
      this.myAppointmentsData = _modules.getAppointments()
      this.appointmentMedicalCenters = this.myAppointmentsData.medicalCenters
      this.appointmentReasons = this.myAppointmentsData.reasons
      this.appointmentTypes = this.myAppointmentsData.types
      this.appointmentConsents = this.myAppointmentsData.informedConsents
      console.log(this.myAppointmentsData.appointments)
      this.myAppointmentsData.appointments.forEach(element => {
        element.date = moment(element.date, 'yyyy-MM-DD HH:mm')
        const now = moment().format('YYYYMMDD')
        if (now > moment(element.date).format('YYYYMMDD')) element.expired = true
        if (element.anulada === 1) element.expired = true
        element.type = 6
        this.appointmentReasons.forEach(reason => {
          if (reason.id.languageCode === 'es') {
            element.reason = reason.name
            element.reasonId = reason.id.itemId
          }
        })
        element.informedConsents = []
        this.appointmentConsents.forEach(consent => {
          if (consent.appointmentId === element.id) {
            element.informedConsents.push(consent)
            console.log(consent)
          }
        })
        this.appointmentTypes.forEach(typeTitle => {
          if (typeTitle.id.languageCode === 'es') {
            element.typeTitle = typeTitle.name
          }
        })
        element.medicalCenterInfo = this.appointmentMedicalCenters.find(x => x.id === element.medicalCenterId)
        this.myAppointments.push(element)
      })
      this.myAppointments = this.sortedAppointments()
      this.skeletonIsVisible = false
    },
    sortedAppointments: function () {
      this.myAppointments.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
      console.log(this.myAppointments)
      console.log(this.appointmentId)
      console.log(this.myAppointments)
      this.appointment = this.myAppointments.find(x => x.id === this.appointmentId)
      console.log(this.appointment)
      return this.myAppointments
    },
    getUriData (uri) {
      this.isLoading = true
      consentService.directLink(uri)
        .then((response) => {
          // console.log()
          console.log(response)
          // this.$i18n.locale = this.languages.find(x => x.id === response.data.userDetailsJwt.language).code
          sessionStorage.setItem('token', response.data.userDetailsJwt.token)
          // sessionStorage.setItem('language', response.data.userDetailsJwt.language)
          const token = 'Bearer ' + response.data.userDetailsJwt.token
          this.$store.commit('setCurrentUser', response.data.userDetailsJwt)
          this.$store.commit('setToken', token)
          // this.$store.commit('changeLocale')
          this.appointmentId = response.data.appointmentId
          userService.getCompanies()
            .then((r) => {
              this.$store.commit('setClientModules', r.data)
              sessionStorage.setItem('_modules', r.data)
              this.getMyAppointments().finally(() => {
                this.initialize(this.appointment)
                this.$store.dispatch('fetchProvinces')
                this.$store.dispatch('fetchEmploymentHistory')
                this.getCities(this.getCurrentUser.provinceId)
                this.isLoading = false
                this.finallyLoad = true
              })
            })
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
          const termAcceptance = ['ERR_LOGIN_003', 'ERR_LOGIN_004', 'ERR_LOGIN_005']
          if (error.response.data.message === 'ERR_CONSENT_003' || error.response.data.message === 'ERR_CONSENT_004' || error.response.data.message === 'ERR_CONSENT_005') {
            this.$router.push({
              name: 'Login',
              params: {
                error: error.response.data.message
              }
            })
          }
          if (termAcceptance.indexOf(error.response.data.message) !== -1) {
            this.checkTerms(error.response.data.message)
          }
        })
        .finally(() => {
        })
    },
    showPrevingTerms (visibility, legalContent, mode) {
      // console.log()
      if (legalContent === 'COOKIES') {
        this.$store.dispatch('showCookiesBar')
        return
      }
      this.isModalVisible = visibility
      this.legalContent = legalContent
      this.isShowPrevingTermsVisible = visibility

      // console.log('entra aqui showPrevingTerms', this.computedServiceTerms)
      if (legalContent === 'TERMS') {
        this.computedText = this.computedServiceTerms
      } else if (legalContent === 'PRIVACY') {
        this.computedText = this.computedPrivacyTerms
      }
      this.mode = mode
      window.scrollTo(0, 0)
      // this.$store.commit('setLegalContent', legalContent)
    },
    checkTerms: function (error) {
      if (error === 'ERR_LOGIN_005') {
        // must to accept both
        this.acceptedTerms.privacy = null
        this.acceptedTerms.terms = null
        console.log('ERR_LOGIN_005')
        this.showPrevingTerms(true, 'TERMS', true)
      } else if (error === 'ERR_LOGIN_003') {
        this.acceptedTerms.terms = null
        this.acceptedTerms.privacy = 1
        this.showPrevingTerms(true, 'TERMS', true)
      } else if (error === 'ERR_LOGIN_004') {
        this.acceptedTerms.privacy = null
        this.acceptedTerms.terms = 1
        this.showPrevingTerms(true, 'PRIVACY', true)
      }
    },
    setServiceTerms: function () {
      console.log('set setServiceTerms')
      this.serviceTerms = decodeURIComponent(atob(this.serviceTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    setPrivacyTerms: function () {
      this.privacyTerms = decodeURIComponent(atob(this.privacyTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    acceptTerms: function (event) {
      // console.log()
      /*
      if (this.acceptedTerms.terms === 1 && this.acceptedTerms.privacy === 1) {
        this.isLoading = true
      }
      */

      this.isLoading = true
      if (event === 'TERMS') {
        this.acceptedTerms.terms = 1
        this.sendAcceptedTerm(event).finally(() => {
          if (this.acceptedTerms.terms === 1 && this.acceptedTerms.privacy === 1) {
            this.getUriData(this.$route.params.uri)
          }
        })
      }
      if (event === 'PRIVACY') {
        this.acceptedTerms.privacy = 1
        this.sendAcceptedTerm(event).finally(() => {
          if (this.acceptedTerms.terms === 1 && this.acceptedTerms.privacy === 1) {
            this.getUriData(this.$route.params.uri)
          }
        })
      }

      if (!this.acceptedTerms.terms) this.showPrevingTerms(true, 'TERMS', true)
      if (!this.acceptedTerms.privacy) this.showPrevingTerms(true, 'PRIVACY', true)
      this.isLoading = false
    },
    sendAcceptedTerm (type) {
      return consentService.loginTerms(this.$route.params.uri, type)
        .then((response) => {
          console.log(response)
        })
        .catch(() => {
          // console.log()
        })
    }
  },
  filters: {
    // use -> {{item.date | moment}}
    moment: function (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('DD/MM/YYYY')
    },
    moment2: function (date) {
      console.log(date)
      if (!date) {
        return '-'
      }
      return moment(date, 'YYYY-MM-DDThh:mm:ss').format('DD/MM/YYYY')
    }
  },
  watch: {
    getEmploymentHistoryStatus: {
      handler (newVal) {
        if (newVal === 'success') {
          this.finallyEmploymentHistory = true
          this.employmentHistoryData = this.getEmploymentHistory
          this.myWorkPlace = this.filterData()
          this.isEmploymentHistoryLoading = false
        }
      },
      deep: true
    },
    getProvincesStatus: {
      handler (newVal) {
        if (newVal === 'success') {
          this.finallyProvinces = true
          this.provinces = this.getProvinces
          this.isProvincesLoading = false
          this.setInitialProvince()
        }
      },
      deep: true
    },
    getPrivacyTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          console.log('entra aqui')
          this.privacyTermsi18n = this.getPrivacyTerms
          this.setPrivacyTerms()
        }
      },
      deep: true
    },
    getServiceTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.serviceTermsi18n = this.getServiceTerms
          this.setServiceTerms()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>

.section__container {
  padding: 0 0 20px 0;
}

  .section__header {
    height: 82px;
    padding-right: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    position: sticky;
    z-index: 10;
    top: 0px;
    background-color: var(--white-color);
  }

  // .section__content--m {
  //   margin-top: 50px
  // }

  h4 {
    font-family: Helvetica,Arial,sans-serif !important;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .button-content {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .little-advise {
    padding: 16px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.yellow {
      background-color: #FAECD2 !important;
    }
    &.blue {
      background-color: #ebf2fa !important;
    }
    & p {
      margin-bottom: 0;
    }
  }

  input[type="checkbox"].final-checkbox {
    transform: scale(1.5);
    min-width: 16px;
    min-height: 16px;
    margin: 6px 15px 0 0;
  }

#content-tabs {
      position: sticky;
    z-index: 9;
    top: 82px;
    background-color: var(--white-color);
}

ol#label-tags {
  width: 100%;
  position: sticky;
  top: 82px;
  padding: 25px 0;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  gap: 19px;
  list-style: none;
  background-color: var(--white-color);
  text-align: center;
}

li#figure {
    width: 32%; // 200px
    height: 56px;
    position: relative;
    background: var(--black-10-color);
    padding: 20px 36px;
    color: var(--black-60-color);
    font-weight: 600;
    user-select: none;
    &.active-li {
      color: var(--white-color);
      background: var(--primary-dark-color);
      &:before {
        border-left: 20px solid var(--primary-dark-color);
      }
    }
}
li#figure:first-child:after {
  color: red !important;
  border-left: 0px solid white;
}

li#figure:after {
  content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 18px solid white;
    border-top: 28px solid transparent;
    border-bottom: 28px solid transparent;
}
li#figure:before {
  content: "";
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid var(--black-10-color);
    border-top: 28px solid transparent;
    border-bottom: 28px solid transparent;
}

.acceptance-text ol li{
  margin-bottom: 15px;
}

@media (max-width: 800px) {

  .section__header > div {
        display: flex;
            align-items: center;
  }

  .paginator {
    display: none;
  }

  li#figure {
    padding: 20px 0px;
    font-size: 12px;
    white-space: nowrap;
    pointer-events: none;
  }
}

@media (max-width: 400px) {

  .button-content {
    flex-direction: column;
  }

  ol#label-tags {
    gap: 12px;
    padding: 0;
    padding-top: 10px;
  }

  li#figure {
    padding: 20px 0px;
    font-size: 10px;
    white-space: nowrap;
    pointer-events: none;
  }
  li#figure.active-li:before {
    border-left: 13px solid var(--primary-dark-color);
  }
  li#figure:before {
    right: -13px;
    border-left: 13px solid var(--black-10-color);
  }
  li#figure:after {
    border-left: 8px solid white;
  }

  .little-advise.blue {
        flex-direction: column;
        gap: 11px;
  }
}

.spinner__init {
    transform: scale(3);
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
  }

</style>
